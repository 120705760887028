import * as types from "../ActionTypes/filtersType";

const initialState = {
    userfilters: [],
    // getAllUsers: [],
    // newusers: [],
    // deleteUser: [],
    // // updateuser: [],
    isLoading: false,
    isSuccess: false,
};

const filtersReducer = (state = initialState, action) => {
    switch (action.type) {
      
        case types.FILTERS_USERS_START:
       
            return {
                isSuccess: false,
                isLoading: true,
            };
     
        case types.FILTERS_USERS_SUCCESS:
            return {
                ...state,
                getAllUsers: action.payload,
                isLoading: false,
                isSuccess: false,
            };
    
       
        
       
        case types.FILTERS_USERS_ERROR:
     

        default:
            return state;
    }
};

export default filtersReducer;

import * as types from "../ActionTypes/reportTypes";

export const getReportStart = (report) => ({
    type: types.GET_REPORT_START,
    payload:report,
});

export const getReportSuccess = (report) => (
    console.log('getReportSuccess~~~~~~~~~~',report),
    {
    type: types.GET_REPORT_SUCCESS,
    payload: report,
});

export const getReportError = (error) => ({
    type: types.GET_REPORT_ERROR,
    payload: error,
});
import * as types from "../ActionTypes/projectType";

const initialState = {
    getAllProjects: [],
    newProjects: [],
    deleteProjects: [],
    updateProjects: [],
    projectView: [],
    isLoading: false,
    isSuccess: false,
};

const projectsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.LOAD_PROJECTS_START:
        case types.ADD_PROJECTS_START:
        case types.DELETE_PROJECTS_START:
        case types.UPDATE_PROJECTS_START:
            return {
                ...state,
                isSuccess: false,
                isLoading: true,
            };
            case types.PROJECTS_VIEW_START:
                return {
                    ...state,
                    isSuccess: false,
                    isLoading: true,
                };
        case types.LOAD_PROJECTS_SUCCESS:
            return {
                ...state,
                getAllProjects: action.payload,
                isLoading: false,
                isSuccess: false,
            };
        case types.ADD_PROJECTS_SUCCESS:
            return {
                ...state,
                newProjects: action.payload,
                isLoading: false,
                isSuccess: true,
            };
        case types.DELETE_PROJECTS_SUCCESS:
            return {
                ...state,
                deleteProjects: action.payload,
                isLoading: false,
                isSuccess: true,
            };
        case types.UPDATE_PROJECTS_SUCCESS:
            return {
                ...state,
                updateProjects: action.payload,
                isLoading: false,
                isSuccess: true,
            };
        case types.PROJECTS_VIEW_SUCCESS:
            return {
                ...state,
                projectView: action.payload,
                isLoading: false,
                isSuccess: false,
            };
        case types.LOAD_PROJECTS_ERROR:
        case types.ADD_PROJECTS_ERROR:
        case types.DELETE_PROJECTS_ERROR:
        case types.UPDATE_PROJECTS_ERROR:
        case types.PROJECTS_VIEW_ERROR:

        default:
            return state;
    }
};

export default projectsReducer;

import * as types from "../ActionTypes/ransporterType";

const initialState = {
    getAllTransporter: [],
    newTransporter: [],
    deleteTransporter: [],
    updateTransporter: [],
    isLoading: false,
    isSuccess: false,
};

const transporterReducers = (state = initialState, action) => {
    switch (action.type) {
        case types.LOAD_TRANSPORTER_START:
        case types.ADD_TRANSPORTER_START:
        case types.DELETE_TRANSPORTER_START:
            case types.UPDATE_TRANSPORTER_START:
            return {
                isSuccess: false,
                isLoading: true,
            };

        case types.LOAD_TRANSPORTER_SUCCESS:
            return {
                ...state,
                getAllTransporter: action.payload,
                isLoading: false,
                isSuccess: false,
            };
        case types.ADD_TRANSPORTER_SUCCESS:
            return {
                ...state,
                newTransporter: action.payload,
                isLoading: false,
                isSuccess: true,
            };
        case types.DELETE_TRANSPORTER_SUCCESS:
            return {
                ...state,
                deleteTransporter: action.payload,
                isLoading: false,
                isSuccess: true,
            };
        case types.UPDATE_TRANSPORTER_SUCCESS:
            return {
                ...state,
                updateTransporter: action.payload,
                isLoading: false,
                isSuccess: true,
            };
        case types.LOAD_TRANSPORTER_ERROR:
        case types.ADD_TRANSPORTER_ERROR:
        case types.DELETE_TRANSPORTER_ERROR:
        case types.UPDATE_TRANSPORTER_ERROR:

        default:
            return state;
    }
};

export default transporterReducers;

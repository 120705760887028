import React from "react";
import AdminDashboard from "./components/AdminDashboard";
import Login from "./pages/Login";
import { Redirect, Route, Switch } from "react-router-dom"

const App = () => {
    return (
        <div>
            {sessionStorage.getItem("ADMIN") ? <Redirect to="/admindashboard" /> : <Redirect to="/login" />}
            <Switch>
                <Route path="/login">
                    <Login />
                </Route>
                <Route path="/">
                    <AdminDashboard />
                </Route>
            </Switch>
        </div>
    );
};

export default App;




export const LOAD_TASKS_START = "LOAD_TASKS_START";
export const LOAD_TASKS_SUCCESS = "LOAD_TASKS_SUCCESS";
export const LOAD_TASKS_ERROR = "LOAD_TASKS_ERROR";

export const ADD_TASKS_START = "ADD_TASKS_START";
export const ADD_TASKS_SUCCESS = "ADD_TASKS_SUCCESS";
export const ADD_TASKS_ERROR = "ADD_TASKS_ERROR";

export const DELETE_TASKS_START = 'DELETE_TASKS_START'
export const DELETE_TASKS_SUCCESS = 'DELETE_TASKS_SUCCESS'
export const DELETE_TASKS_ERROR = 'DELETE_TASKS_ERROR'


export const UPDATE_TASKS_START = 'UPDATE_TASKS_START'
export const UPDATE_TASKS_SUCCESS = 'UPDATE_TASKS_SUCCESS'
export const UPDATE_TASKS_ERROR = 'UPDATE_TASKS_ERROR'
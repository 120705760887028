import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { getReportStart } from "../../redux/Actions/reportAction";
import { Dropdown } from "primereact/dropdown";
import { baseUrl } from "../../redux/APIs/baseUrl";
import classNames from "classnames";

const emptyFields = {
    report_type: "",
};

const Reports = () => {
    const [data, setData] = useState(emptyFields);
    const { report_type } = data;
    const [submitted, setSubmitted] = useState(false);
    const { id } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const [editMode, setEditMode] = useState(false);
    const users = useSelector((state) => state?.loginData?.getAllUsers);
    const getallprojects = useSelector((state) => state?.getprojects);
    const ProjectsData = getallprojects?.getAllProjects;
    const partsData = useSelector((state) => state?.parts?.partsList);
    const part_d = useSelector((state) => state?.parts);
    const getreport = useSelector((state) => state?.getReport);
    const [downloadUrl, setDownloadUrl] = useState("");

    const [flag, setflag] = useState(false);
    const [showDownload, setShowDownload] = useState(false);

    const isSuccess = getreport.isSuccess;

    console.log(getreport, "getreportgetre1111111111111111111111111ortgetreport");
    useEffect(() => {
        if (getreport !== undefined) {
            console.log(getreport, "getreportgetreportgetreportgetreportgetreport");
            const subBaseUrl = baseUrl.substring(0, baseUrl.length - 1);

            const URL = `${subBaseUrl}${getreport?.reportList?.url}`;
            console.log("urllllllllllllll~~~~~~~>", URL);

            if (getreport?.reportList?.url !== undefined) {
                setDownloadUrl(URL);
                setShowDownload(true);
            }
            // window.open(URL);

            // let alink = document.createElement("a");
            // alink.href = `${subBaseUrl}${getreport}`;
            // alink.download = "SamplePDF.pdf";
            // alink.click();

            // --------------------------------------------------------

            // const link = document.createElement("a",null);
            // link.href = URL;
            // link.setAttribute("download", `FileName.pdf`);

            // document.body.appendChild(link);

            // link.click();

            // link.parentNode.removeChild(link);
        }
    }, [isSuccess]);

    const ReportOption = [{ id:1,label: "inventory" }, { id:2,label: "employee" }, { id:3,label: "project" }];

    const gotoPrevious = () => {
        history.goBack();
    };

    const generateReport = (e) => {
        // e.preventDefault();
        setSubmitted(true);
        const add_parts = {
            projet_id: data?.projet_id?.label,
        };
        dispatch(getReportStart(add_parts));
        // console.log("baseUrl~~~~~~~~~~~~>", baseUrl.substring(0, baseUrl.length - 1));

        // // setflag(true);

        // const subBaseUrl = baseUrl.substring(0, baseUrl.length - 1);

        // const URL = `${subBaseUrl}${getreport !== undefined && getreport}`;
        // // window.open(URL);

        // let alink = document.createElement("a");
        // alink.href = `${subBaseUrl}${getreport}`;
        // alink.download = "SamplePDF.pdf";
        // alink.click();
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        setData({ ...data, [name]: val });
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h2>Reports</h2>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Back" icon="pi pi-angle-left" className="p-button-secondary mr-2" onClick={gotoPrevious} />
                </div>
            </React.Fragment>
        );
    };

    return (
        <div className="surface-section card" style={{ margin: "1%", padding: "1%" }}>
            <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
            <div className="col-12 md:col-6">
                <div className="card p-fluid">
                    <div className="field">
                        <label htmlFor="Id">Select Report Type</label>
                        {/* <Dropdown key={"id"} value={data.projet_id} onChange={(e) => onInputChange(e, "projet_id")} options={ReportOption} optionLabel="label" placeholder="Select Report" className={classNames({ "w-full md:w-50rem": submitted && !data.projet_id })} />
                        {submitted && !data.projet_id && <small className="p-error">Select any report.</small>} */}
                        <Dropdown
                            key={'id'}
                            value={data.projet_id}
                            onChange={(e) => onInputChange(e, 'projet_id')}
                            options={ReportOption}
                            optionLabel="label"
                            placeholder="Select Report"
                            className="w-full md:w-50rem"
                        />
                        {(submitted && !data.projet_id && <small className="p-error">Select report is required.</small>)}
                    </div>

                    <div className="formgrid grid">
                        <div className="field col">
                            <Button label="Cancel" icon="pi pi-times" className="p-button-secondary mr-2 mb-2" onClick={gotoPrevious} />
                        </div>
                        <div className="field col">
                            <Button label={"Generate Report"} icon="pi pi-check" className="p-button-warning mr-2 mb-2" onClick={generateReport} />
                        </div>
                        {showDownload && (
                            <div className="field col">
                                <Button
                                    label={"Download Report"}
                                    icon="pi pi-check"
                                    className="p-button-warning mr-2 mb-2"
                                    onClick={() => {
                                        window.open(downloadUrl);
                                        setShowDownload(false);
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Reports;

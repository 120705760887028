import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { adminLoginStart } from "../redux/Actions/actions";
import { Password } from "primereact/password";
import { Button } from "primereact/button";

const Login = () => {
    const [data, setData] = useState({
        username: "",
        password: "",
    });
    const history = useHistory();
    const dispatch = useDispatch();
    const [submitted, setSubmitted] = useState(false);
    const loginData = useSelector((state) => state.loginData);
    const isSucess = loginData?.isSuccess;
    const isLoading = loginData?.isLoading;

    useEffect(() => {
        if (isSucess) {
            tokenGet();
        }
    }, [isSucess]);
    
    const tokenGet = async () => {
        const token = await sessionStorage.getItem("ADMIN");
        if(!!token){
            window.location.href = "/admindashboard";
        }
    }

    const handleChange = (e) => {
        const value = e.target.value;
        setData({
            ...data,
            [e.target.name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true);
        setData(data);
        if (data.username != "" && data.password != "") {
            dispatch(adminLoginStart(data));
        }
    };
    return (
        <div className="flex justify-content-center border-round mt-8">
            <div className="card w-30rem ">
                <div className="flex justify-content-center">
                    <img src="assets/layout/images/logo.png" alt="logo" style={{ width: "50%" }} />
                </div>

                <h3 className="text-center mb-8">LOG IN</h3>
                <form onSubmit={handleSubmit} className="p-fluid">
                    <div className="formgrid grid">
                        <div className="field col">
                            <label>Enter Username</label>
                        </div>

                        <div className="field col p-input-icon-right">
                            <InputText className={classNames({ "p-invalid": submitted && !data.username })} id="username" name="username" label="User Name" placeholder="Enter Username" value={data.username} onChange={handleChange} autoFocus />
                            {submitted && !data.username && <small className="p-error">UserName is required.</small>}
                        </div>
                        <div></div>
                    </div>

                    <div className="formgrid grid">
                        <div className="field col">
                            <label>Enter Password</label>
                        </div>

                        <div className="field col">
                            <Password placeholder="Enter password" className={classNames({ "p-invalid": submitted && !data.password })} id="password" name="password" label="password" type="password" value={data.password} onChange={handleChange} toggleMask feedback={false} />
                            {submitted && !data.password && <small className="p-error">Password is required.</small>}
                        </div>
                        <div></div>
                    </div>

                    <div className="formgrid grid">
                        <div className="field col">
                            <Button label="Log In" loading={isLoading} className="p-button-success mr-2 mb-2" />
                        </div>
                    </div>
                    {/* <div className="formgrid grid">
                        <div className="field col" style={{ display: "flex" }}>
                            <p>New User?</p>
                            <Link to="/register">
                                <p style={{ marginLeft: 5 }}>Sign Up</p>
                            </Link>
                        </div>
                    </div> */}
                </form>
            </div>
        </div>
    );
};

export default Login;

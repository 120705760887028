import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dropdown } from "primereact/dropdown";
import classNames from "classnames";
import { addProjectsStart, updateProjectsStart } from "../../redux/Actions/projectAction";
import { loadUsersStart } from "../../redux/Actions/actions";
import { MultiSelect } from "primereact/multiselect";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import { loadinventoryStart } from "../../redux/Actions/inventoryAciton";

const emptyFields = {
    emp: "",
    project_name: "",
    project_pricing: "",
    inventory: "",
    working_status: "",
    project_description: "",
};

const AddEditProject = () => {
    const [data, setData] = useState(emptyFields);
    const { emp, project_name, project_pricing, inventory, working_status, project_description } = data;
    const [submitted, setSubmitted] = useState(false);
    const { id } = useParams();
    const [editMode, setEditMode] = useState(false);
    const [mediaError, setMediaError] = useState(false);
    const [selectedValues, setSelectedValues] = useState([]);
    const [selectedInventory, setSelectedInventory] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState([]);
    const users = useSelector((state) => state?.loginData?.getAllUsers); // emp list
    const inventorydata = useSelector((state) => state?.getinventory?.getAllInventory?.data); //inventorydata list
    const isSuccess = useSelector((state) => state?.addProjects?.newProjects?.status);
    const getallprojects = useSelector((state) => state?.getprojects?.getAllProjects);
    const isSuccessUpdate = useSelector((state) => state?.updateProjects?.updateProjects?.status);

    const options = users?.map((item) => {
        return { label: item.username, value: item.id };
    });

    const inventoryoptions = inventorydata?.map((item) => {
        return { label: item?.inventory_name, value: item.id };
    });

    const status = [
        { name: "Pending", code: "Pending" },
        { name: "Complete ", code: "Complete" },
        { name: "InProgress", code: "InProgress" },
        { name: "Hold", code: "Hold" },
        // { name: "InProgress", code: "InProgress" },
    ];
    useEffect(() => {
        if (id) {
            setEditMode(true);
            const dataUpdate = getallprojects ? getallprojects?.find((item) => item.id === Number(id)) : null;
            const empdata = [];
            const inventoryData = [];
            dataUpdate?.emp?.map((item) => {
                empdata.push(item.id);
            });
            dataUpdate?.inventory?.map((item) => {
                inventoryData.push(item.id);
            });
            setData({ ...dataUpdate, emp: empdata, inventory: inventoryData });
        } else {
            setEditMode(false);
            setData({ ...data });
        }
    }, [id]);

    const gotoPrevious = () => {
        history.goBack();
    };

    const history = useHistory();
    const dispatch = useDispatch();
    useEffect(() => {
        if (isSuccess === 201) {
            history.push("/admindashboard/projects-list");
        }
    }, [isSuccess]);
    useEffect(() => {
        if (isSuccessUpdate === 201) {
            history.push("/admindashboard/projects-list");
        }
    }, [isSuccessUpdate]);
    useEffect(() => {
        dispatch(loadUsersStart());
        dispatch(loadinventoryStart());
    }, []);

    const addUProject = (e) => {
        e.preventDefault();
        setSubmitted(true);
        if (!editMode) {
            const add_Project = {
                project_name: data?.project_name,
                project_pricing: data?.project_pricing,
                inventory: data?.inventory,
                working_status: data?.working_status,
                project_description: data?.project_description,
                emp: data?.emp,
            };
            dispatch(addProjectsStart(add_Project));
        } else {
            const update_Users = {
                id: id,
                emp: data?.emp,

                project_name: data?.project_name,
                project_pricing: data?.project_pricing,
                inventory: data?.inventory,
                working_status: data?.working_status,
                project_description: data?.project_description,
            };
            dispatch(updateProjectsStart(update_Users, id));
        }
    };

    const onInputChange = (e, name) => {
        const value = e.target.value;

        setData({
            ...data,
            [e.target.name]: value,
        });
    };
   

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <div className="font-medium text-4xl text-900 mb-3"> {!editMode ? "Add New Project" : `Update Project`}</div>
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Back" icon="pi pi-angle-left" className="p-button-secondary mr-2" onClick={gotoPrevious} />
                </div>
            </React.Fragment>
        );
    };

    return (
        <div className="surface-section card" style={{ margin: "1%", padding: "1%" }}>
            <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
            <div className="col-12 md:col-6">
                <div className="card p-fluid">
                    <div className="field">
                        <label htmlFor="Id">Project Name</label>
                        <InputText id="id" name="project_name" value={data?.project_name} onChange={(e) => onInputChange(e, "project_name")} className={classNames({ "p-invalid": submitted && !data.project_name })} autoFocus />
                        {submitted && !data.project_name && <small className="p-error">Project Name is required.</small>}
                    </div>

                    <div className="field">
                        <label htmlFor="Id">Project Price</label>
                        <InputText id="id" name="project_pricing" type="number" value={data?.project_pricing} onChange={(e) => onInputChange(e, "project_pricing")} className={classNames({ "p-invalid": submitted && !data.project_pricing })} autoFocus />
                        {submitted && !data.project_pricing && <small className="p-error">Project price is required.</small>}
                    </div>
                    <div className="field">
                        <label htmlFor="Id">Project Description</label>
                        <InputText id="id" name="project_description" value={data?.project_description} onChange={(e) => onInputChange(e, "project_description")} className={classNames({ "p-invalid": submitted && !data.project_description })} autoFocus />
                        {submitted && !data.project_description && <small className="p-error">Project description is required.</small>}
                    </div>

                    <div className="field">
                        <label htmlFor="name">Select Employee</label>
                        <MultiSelect name="emp" value={data.emp} options={options} onChange={(e) => onInputChange(e, "emp")} placeholder="Select options" />
                        {submitted && !data.emp && <small className="p-error">Select employee.</small>}
                    </div>
                    <div className="field">
                        <label htmlFor="name">Select Inventory</label>
                        <MultiSelect name="inventory" value={data?.inventory} options={inventoryoptions} onChange={(e) => onInputChange(e, "inventory")} placeholder="Select options" />
                        {submitted && !data?.inventory && <small className="p-error">Select inventory.</small>}
                    </div>
                    <div className="field">
                        <label htmlFor="Id">Project Status</label>
                        <Dropdown name="working_status" id="name" optionValue="name" value={data?.working_status} onChange={(e) => onInputChange(e, "working_status")} options={status} optionLabel="name" showClear placeholder="Select a Status" className="w-full md:w-40rem" />
                        {submitted && !data?.working_status && <small className="p-error">Select project status.</small>}
                    </div>

                    <div className="formgrid grid">
                        <div className="field col">
                            <Button label="Cancel" icon="pi pi-times" className="p-button-secondary mr-2 mb-2" onClick={gotoPrevious} />
                        </div>
                        <div className="field col">
                            <Button label={!editMode ? "Add" : "UPDATE"} icon="pi pi-check" className="p-button-warning mr-2 mb-2" onClick={addUProject} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddEditProject;

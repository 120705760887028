import * as types from "../ActionTypes/tasksType";

const initialState = {
   
    getAllTask: [],
    newTasks: [],
    deleteTasks: [],
    updateTasks: [],
    isLoading: false,
    isSuccess: false,
};

const tasksReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.LOAD_TASKS_START:
        case types.ADD_TASKS_START:
        case types.DELETE_TASKS_START:
        case types.UPDATE_TASKS_START:
            return {
                isSuccess: false,
                isLoading: true,
            };
       
        case types.LOAD_TASKS_SUCCESS:
            return {
                ...state,
                getAllTask: action.payload,
                isLoading: false,
                isSuccess: false,
            };
        case types.ADD_TASKS_SUCCESS:
            return {
                ...state,
                newTasks: action.payload,
                isLoading: false,
                isSuccess: true,
            };
        case types.DELETE_TASKS_SUCCESS:
            return {
                ...state,
                deleteTasks: action.payload,
                isLoading: false,
                isSuccess: true,
            };
        case types.UPDATE_TASKS_SUCCESS:
            return {
                ...state,
                updateTasks: action.payload,
                isLoading: false,
                isSuccess: true,
            };
        case types.LOAD_TASKS_ERROR:
        case types.ADD_TASKS_ERROR:
        case types.DELETE_TASKS_ERROR:
        case types.UPDATE_TASKS_ERROR:

        default:
            return state;
    }
};

export default tasksReducer;

import axios from "axios";
import { baseUrl } from "./baseUrl";

const token = JSON.parse(sessionStorage.getItem("ADMIN"));
const headersParam = {
    Authorization: `Token ${token}`,
    Origin: "*",
};

// const baseUrl = "https://shreevishnuapi.koliinfotech.com/";

// export const adminLoginApi = async (user) => await axios.post(`${baseUrl}/login/`, user);

export const addInventorysApi = async (inventory) => await axios.post(`${baseUrl}inventory/`, inventory, { headers: headersParam });

export const deleteInventorysApi = async (inventoryId) => await axios.delete(`${baseUrl}deletetinventory/${inventoryId.id}`, { headers: headersParam });

export const loadInventorysApi = async () => await axios.get(`${baseUrl}allinventory/`, { headers: headersParam });

export const UpdateInventory = async (inventory) => await axios.put(`${baseUrl}updateinventory/${inventory?.id}/`, inventory, { headers: headersParam });

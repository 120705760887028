import * as types from "../ActionTypes/partsTypes";

export const getPartsStart = () => ({
    type: types.GET_PARTS_START,
});

export const getPartsSuccess = (parts) => ({
    type: types.GET_PARTS_SUCCESS,
    payload: parts,
});

export const getPartsError = (error) => ({
    type: types.GET_PARTS_ERROR,
    payload: error,
});

export const addPartsStart = (parts) => ({
    type: types.ADD_PARTS_START,
    payload: parts,
});

export const addPartsSuccess = (parts) => ({
    type: types.ADD_PARTS_SUCCESS,
    payload: parts,
});

export const addPartsError = (error) => ({
    type: types.ADD_PARTS_ERROR,
    payload: error,
});

export const deletePartsStart = (parts) => ({
    type: types.DELETE_PARTS_START,
    payload: parts
});

export const deletePartsSuccess = (parts) => ({
    type: types.DELETE_PARTS_SUCCESS,
    payload: parts,
});

export const deletePartsError = (error) => ({
    type: types.DELETE_PARTS_ERROR,
    payload: error,
});

export const updatePartsStart = (parts) => (
    {
        type: types.UPDATE_PARTS_START,
        payload: parts,
    });

export const updatePartsSuccess = (parts) => (
    {
        type: types.UPDATE_PARTS_SUCCESS,
        payload: parts,
    });

export const updatePartsError = (error) => (
    {
    type: types.UPDATE_PARTS_ERROR,
    payload: error,
});
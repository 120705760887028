import * as types from "../ActionTypes/partsTypes";

const initialState = {
    partsList: [],
    partsAdd: [],
    partsUpdate:[],
    isLoading: false,
    isSuccess: false,
};

const partsReducers = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_PARTS_START:
            // return {
            //     ...state,
            //     isLoading:true
            // }
        case types.ADD_PARTS_START:
        case types.DELETE_PARTS_START:
            return {
                isSuccess: false,
                isLoading: true,
            };
        case types.UPDATE_PARTS_START:
            return {
                isSuccess: false,
                isLoading: true,
            };
        case types.GET_PARTS_SUCCESS:
            return {
                ...state,
                partsList: action.payload,
                isLoading: false,
                isSuccess: false,
            };
        case types.ADD_PARTS_SUCCESS:
            return {
                ...state,
                partsAdd: action.payload,
                isLoading: false,
                isSuccess: true,
            };
        case types.DELETE_PARTS_SUCCESS:
            return {
                isLoading: false,
                isSuccess: true,
            };
        case types.UPDATE_PARTS_SUCCESS:
            return {
                isLoading: false,
                isSuccess: true,
            };
        case types.GET_PARTS_ERROR:
        case types.ADD_PARTS_ERROR:
        case types.DELETE_PARTS_ERROR:
        case types.UPDATE_PARTS_ERROR:
        default:
            return state;
    }
};

export default partsReducers;

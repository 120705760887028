import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getAdminLeaveStart } from "../../redux/Actions/adminLeaveAction";
import { Link } from "react-router-dom";

const AdminLeaveApplication = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const dt = useRef(null);
    const [adminLeave, setAdminLeave] = useState();
    const [deleteadminLeaveDialog, setDeleteAdminLeaveDialog] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const adminLeaveList = useSelector((state) => state?.adminLeave);
    const adminLeaveData = adminLeaveList?.adminLeave?.data;
    const isLoading = adminLeaveList?.isLoading;
    const isSuccess = adminLeaveList?.isSuccess;

    useEffect(() => {
        dispatch(getAdminLeaveStart());
    }, []);

    const hideDeletePartsDialog = () => {
        setDeleteAdminLeaveDialog(false);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.is_approved === "Approved" ? (
                    <>
                        <div className="actions">
                            <Link to={`/update-new-adminLeave/${rowData.id}`}>
                                <Button icon="pi pi-check" className="p-button-rounded p-button-success  mt-2 mr-2" />
                            </Link>
                        </div>
                    </>
                ) : rowData.is_approved === "Rejected" ? (
                    <>
                        <div className="actions">
                            <Link to={`/update-new-adminLeave/${rowData.id}`}>
                                <Button icon="pi pi-times" className="p-button-rounded p-button-danger  mt-2 mr-2" />
                            </Link>
                        </div>
                    </>
                ) : (
                    <div className="actions">
                        <Link to={`/update-new-adminLeave/${rowData.id}`}>
                            <Button icon="pi pi-pencil" className="p-button-rounded p-button-info  mt-2 mr-2" />
                        </Link>
                    </div>
                )}
            </>
        );
    };

    const idBodyTemplate = (rowData, index) => {
        return (
            <>
                <div className="actions">
                    <p>
                        <b>{index.rowIndex + 1}</b>
                    </p>
                </div>
            </>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">List Of Parts</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const deleteAdminLeaveDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeletePartsDialog} />
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card" style={{ margin: "1%" }}>
                    <DataTable
                        ref={dt}
                        value={adminLeaveData}
                        loading={isLoading}
                        dataKey="id"
                        paginator
                        rows={5}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Parts"
                        globalFilter={globalFilter}
                        emptyMessage="No Admin Leave application found."
                        header={header}
                        responsiveLayout="scroll"
                    >
                        {/* <Column field="id" header="ID" sortable headerStyle={{ width: "14%", minWidth: "10rem" }}></Column> */}
                        <Column body={idBodyTemplate} header={"Id"}></Column>
                        <Column style={{ display: "none" }} field="uniqueId" header="UNIQUEID" sortable headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="emp_id" header="Employee Id " sortable headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="created_date" header="Created Date" sortable headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="leave_type" header="Leave Type" sortable headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="from_date" header="From Date" sortable headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="to_date" header="To Date" sortable headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="total_leave" header="Total Leave" sortable headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="leave_application" header="Leave Application" sortable headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="is_approved" header="Is Approved" sortable headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column body={actionBodyTemplate} header={"Action"}></Column>
                    </DataTable>

                    <Dialog visible={deleteadminLeaveDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteAdminLeaveDialogFooter} onHide={hideDeletePartsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                            {adminLeave && (
                                <span>
                                    Are you sure you want to delete <b>{adminLeave.id}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(AdminLeaveApplication, comparisonFn);

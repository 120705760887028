import { combineReducers } from "redux";
import usersReducer from "./Reducers/reducer";
import projectsReducer from "./Reducers/projectsReducers";
import inventoryReducers from "./Reducers/inventoryReducers";
import transporterReducers from "./Reducers/transporterReducers";
import filtersReducer from "./Reducers/filtersReducers";
import TasksReducers from "./Reducers/tasksReducer";
import partsReducers from "./Reducers/partsReducers";
import adminLeaveReducers from "./Reducers/adminLeaveReducers";
import reportReducers from "./Reducers/reportReducers";

const rootReducer = combineReducers({
    loginData: usersReducer,
    addusers: usersReducer,
    deleteuser: usersReducer,
    updateuser: usersReducer,
    getprojects: projectsReducer,
    addProjects: projectsReducer,
    projectView: projectsReducer,
    updateProjects:projectsReducer,
    getinventory: inventoryReducers,
    deleteinventory: inventoryReducers,
    addInventory: inventoryReducers,
    updateinventory: inventoryReducers,
    gettransporter: transporterReducers,
    addtransporter: transporterReducers,
    // updateproject:projectsReducer
    filtersReducer: filtersReducer,
    gettasks: TasksReducers,
    addtasks: TasksReducers,
    deletetasks: TasksReducers,
    updatetasks: TasksReducers,
    parts: partsReducers,
    adminLeave: adminLeaveReducers,
    getReport:reportReducers
});

export default rootReducer;

import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import classNames from "classnames";
import { addUsersStart, loadUsersStart, updateUserStart } from "../../redux/Actions/actions";
import { Password } from "primereact/password";
const emptyFields = {
    username: "",
    password: "",
    password2: "",
    email: "",
};

const AddEditUser = () => {
    const [data, setData] = useState(emptyFields);
    const { username, email, password, password2 } = data;
    const [submitted, setSubmitted] = useState(false);
    const { id } = useParams();
    const [editMode, setEditMode] = useState(false);
    const [mediaError, setMediaError] = useState(false);
    const users = useSelector((state) => state?.loginData?.getAllUsers);
    const isSuccess = useSelector((state) => state?.addusers?.newusers?.status);
    const isSuccessUpdate = useSelector((state) => state?.updateuser
    ?.updateuser?.status);

 
    useEffect(() => {
        dispatch(loadUsersStart());
    }, []);
    useEffect(() => {
        if (isSuccessUpdate === 201) {
            history.push("/admindashboard/users-list");
        }
    }, [isSuccessUpdate]);
    useEffect(() => {
        if (isSuccess === 201) {
            history.push("/admindashboard/users-list");
        }
    }, [isSuccess]);

    useEffect(() => {
        if (id) {
            setEditMode(true);
            const dataUpdate = users ? users?.find((item) => item.id === Number(id)) : null;
            setData({ ...dataUpdate });
        } else {
            setEditMode(false);
            setData({ ...data });
        }
    }, [id]);

    const gotoPrevious = () => {
        history.goBack();
    };

    const history = useHistory();
    const dispatch = useDispatch();

    const addUsers = (e) => {
        e.preventDefault();
        setSubmitted(true);
        if (!editMode) {
            const add_Users = {
                username: username,
                email: email,
                password: password,
                password2: password2,
            };
            dispatch(addUsersStart(add_Users));
            // history.push("/admindashboard/users-list");
        } else {
            const update_Users = {
                id: id,
                username: username,
                email: email,
                password: password,
                password2: password2,
            };
            dispatch(updateUserStart(update_Users, id));
            // history.push("/admindashboard/users-list");
        }
    };

    const onInputChange = (e) => {
        const value = e.target.value;
        setData({
            ...data,
            [e.target.name]: value,
        });
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <div className="font-medium text-4xl text-900 mb-3"> {!editMode ? "Add New Users" : `Update Users`}</div>
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Back" icon="pi pi-angle-left" className="p-button-secondary mr-2" onClick={gotoPrevious} />
                </div>
            </React.Fragment>
        );
    };

    return (
        <div className="surface-section card" style={{ margin: "1%", padding: "1%" }}>
            <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
            <div className="col-12 md:col-6">
                <div className="card p-fluid">
                    <div className="field">
                        <label htmlFor="Id">User Name</label>
                        <InputText id="id" name="username" value={data?.username} onChange={(e) => onInputChange(e, "username")} className={classNames({ "p-invalid": submitted && !data.username })} autoFocus />
                        {submitted && !data.username && <small className="p-error">Username is required.</small>}
                    </div>

                    <div className="field">
                        <label htmlFor="Id">Email</label>
                        <InputText id="id" name="email" value={data?.email} onChange={(e) => onInputChange(e, "email")} className={classNames({ "p-invalid": submitted && !data.email })}  />
                        {submitted && !data.email && <small className="p-error">Email is required.</small>}
                    </div>
                    <div style={editMode ? { display: "none" } : null} className="formgrid grid">
                        <div className="field col">
                            <label htmlFor="Id"> Password</label>
                            <Password id="id" name="password" value={data?.password} onChange={(e) => onInputChange(e, "password")} className={classNames({ "p-invalid": submitted && !data.password })} toggleMask />
                            {submitted && !data.password && <small className="p-error">Password is required.</small>}
                        </div>

                        <div className="field col">
                            <label htmlFor="Id">Confirm Password</label>
                            <Password id="id" name="password2" value={data?.password2} onChange={(e) => onInputChange(e, "password2")} className={classNames({ "p-invalid": submitted && !data.password2 })}  />
                            {submitted && !data.password2 && <small className="p-error">Confirm is required.</small>}
                        </div>
                    </div>
                    <div className="formgrid grid">
                        <div className="field col">
                            <Button label="Cancel" icon="pi pi-times" className="p-button-secondary mr-2 mb-2" onClick={gotoPrevious} />
                        </div>
                        <div className="field col">
                            <Button label={!editMode ? "Add" : "UPDATE"} icon="pi pi-check" className="p-button-warning mr-2 mb-2" onClick={addUsers} />
                            {/* loading={isLoading}  */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddEditUser;

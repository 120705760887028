// import * as types from "../ActionTypes/partsTypes";
import * as types from "../ActionTypes/reportTypes";

const initialState = {
    reportList: [],
    isLoading: false,
    isSuccess: false,
};

const reportReducers = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_REPORT_START:
            return {
                isSuccess: false,
                isLoading: true,
                reportList: action.payload,
            };

        case types.GET_REPORT_SUCCESS:
            console.log('GET_REPORT_SUCCESS~~~~~',action)
            return {
                ...state,
                reportList: action.payload,
                isLoading: false,
                isSuccess: true,
            };

        case types.GET_REPORT_ERROR:
        default:
            return { ...state,
                 isLoading: false,
                 isSuccess: false };
    }
};

export default reportReducers;

import * as types from "../ActionTypes/inventoryType";
import { takeLatest, put, all, fork, call } from "redux-saga/effects";
import Swal from "sweetalert2";

import { loadinventorySuccess, laodinventoryError, deleteinventorySuccess, deleteinventoryError, addInventoryError, addInventorySuccess, updateInventorySuccess, updateInventoryError } from "../Actions/inventoryAciton";

import { loadInventorysApi, deleteInventorysApi, addInventorysApi,UpdateInventory } from "../APIs/inventoryApi";

const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 4000,
});

export function* onLoadInventoryStartAsync() {
    try {
        const response = yield call(loadInventorysApi);
         if (response.status === 200) {
            yield put(loadinventorySuccess(response));
        }
    } catch (error) {
        yield put(laodinventoryError(error));
    }
}

export function* onAddInventoryStartAsync({ payload }) {
    try {
        const response = yield call(addInventorysApi, payload);
        // if (response?.data?.status === true) {
            yield put(addInventorySuccess(response));

            Toast.fire({
                icon: "success",
                title: response?.data?.msg,
            });
        // } 
        // else {
        //     Toast.fire({
        //         icon: "error",
        //         title: response?.data?.msg,
        //     });
        //     return response;
        // }
    } catch (error) {
        yield put(addInventoryError(error.response));
        // Toast.fire({
        //     icon: "error",
        //     title: error.response?.data?.msg,
        // });
    }
}

export function* onDeleteInventoryStartAsync(inventoryId) {
    try {
        const response = yield call(deleteInventorysApi, inventoryId.payload);
        if (response?.status === 201) {
            yield put(deleteinventorySuccess(inventoryId));
            Toast.fire({
                icon: "success",
                title: response.data.msg,
            });
        } else {
            Toast.fire({
                icon: "error",
                title: response.data.msg,
            });
        }
    } catch (error) {
        yield put(deleteinventoryError(error.response));
    }
}

export function* onUpdateInventoryAsync(updateinventory) {
    try {
        const response = yield call(UpdateInventory, updateinventory?.payload);
        if (response?.status === 201) {
            yield put(updateInventorySuccess(response));
            Toast.fire({
                icon: "success",
                title: "User update successfully",
            });
        } else {
            Toast.fire({
                icon: "error",
                title: response.data.msg,
            });
        }
    } catch (error) {
        yield put(updateInventoryError(error.response));
    }
}
export function* onLoadInventory() {
    yield takeLatest(types.LOAD_INVENTORY_START, onLoadInventoryStartAsync);
}

export function* onDeleteInventory() {
    yield takeLatest(types.DELETE_INVENTORY_START, onDeleteInventoryStartAsync);
}

export function* onAddInventory() {
    yield takeLatest(types.ADD_INVENTORY_START, onAddInventoryStartAsync);
}

export function* onUpdateInvrntory() {
    yield takeLatest(types.UPDATE_INVENTORY_START, onUpdateInventoryAsync);
}
const inventorySagas = [fork(onLoadInventory), fork(onDeleteInventory), fork(onAddInventory), fork(onUpdateInvrntory)];

export default function* inventorysSagas() {
    yield all([...inventorySagas]);
}

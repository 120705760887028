import axios from "axios";
import { baseUrl } from "./baseUrl";
const token = JSON.parse(sessionStorage.getItem("ADMIN"));
const headersParam = {
    Authorization: `Token ${token}`,
    Origin: "*",
};

// const baseUrl = "https://shreevishnuapi.koliinfotech.com/";

// export const adminLoginApi = async (user) => await axios.post(`${baseUrl}/login/`, user);

export const addProjectsApi = async (newproject) => await axios.post(`${baseUrl}project/`, newproject, { headers: headersParam });

export const deleteProjectsApi = async (projectsId) => await axios.delete(`${baseUrl}deleteprojects/${projectsId.id}`, { headers: headersParam });

export const updateProjectApi = async (updateproject) => await axios.put(`${baseUrl}updateprojects/${updateproject?.id}/`, updateproject, { headers: headersParam });

export const loadProjectsApi = async () => await axios.get(`${baseUrl}getallprojects/`, { headers: headersParam });

export const projectsViewApi = async () => await axios.get(`${baseUrl}ProjectPartView/`, { headers: headersParam });


import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
// import { loadProjectsStart } from "../../redux/Actions/projectAction";
import { Dropdown } from "primereact/dropdown";
import { getAdminLeaveStart, updateAdminLeaveStart } from "../../redux/Actions/adminLeaveAction";

const emptyFields = {
    id: "",
    is_approved: [],
};

const AddEditAdminLeave = () => {
    const [data, setData] = useState(emptyFields);
    const { is_approved } = data;
    const [submitted, setSubmitted] = useState(false);
    const { id } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const [editMode, setEditMode] = useState(false);
    const statusArray = [
        { id: 1, is_approved: "Approved" },
        { id: 2, is_approved: "Rejected" },
        { id: 3, is_approved: "Pending" },
    ];
    const adminLeaveList = useSelector((state) => state?.adminLeave)
    const adminLeaveData = adminLeaveList?.adminLeave?.data
    const isLoading = adminLeaveList?.isLoading
    const isSuccess = adminLeaveList?.isSuccess

    useEffect(() => {
        dispatch(getAdminLeaveStart());
    }, []);

    useEffect(() => {
        if (isSuccess === true) {
            history.push("/admindashboard/adminLeave-list");
        }
    }, [isSuccess]);

    useEffect(() => {
        if (id) {
            setEditMode(true);
            const statusValue = adminLeaveData ? adminLeaveData.find((item) => item?.id === Number(id)) : null;
            statusArray.map((item) => {
                if (item.is_approved === statusValue.is_approved) {
                    setData({ ...data, is_approved: item.is_approved, id: item.id })
                }
            })
        } else {
            setEditMode(false);
        }
    }, [id]);

    const gotoPrevious = () => {
        history.goBack();
    };

    const updateAdminLeave = (e) => {
        e.preventDefault();
        setSubmitted(true);
        // if (!editMode) {
            const update_leave = {
                is_approved: data.is_approved,
                id: id,
            };
            dispatch(updateAdminLeaveStart(update_leave));
        // }
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        setData(val)
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <div className="font-medium text-4xl text-900 mb-3"> {!editMode ? "Admin Leave application status" : `Admin Leave application status`}</div>
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Back" icon="pi pi-angle-left" className="p-button-secondary mr-2" onClick={gotoPrevious} />
                </div>
            </React.Fragment>
        );
    };

    return (
        <div className="surface-section card" style={{ margin: "1%", padding: "1%" }}>
            <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
            <div className="col-12 md:col-6">
                <div className="card p-fluid">
                    <div className="field">
                        <label htmlFor="Id">Select Amin Leave status</label>
                        <Dropdown 
                        key={"id"} 
                        value={data} 
                        onChange={(e) => onInputChange(e, "is_approved")} 
                        options={statusArray} 
                        optionLabel="is_approved" 
                        placeholder="Select Status" 
                        className="w-full md:w-50rem" 
                        />
                        {/* {(submitted && !data.projet_id && <small className="p-error">Select Project is required.</small>)} */}
                    </div>

                    <div className="formgrid grid">
                        <div className="field col">
                            <Button label="Cancel" icon="pi pi-times" className="p-button-secondary mr-2 mb-2" onClick={gotoPrevious} />
                        </div>
                        <div className="field col">
                            <Button label={!editMode ? "Add" : "UPDATE"} icon="pi pi-check" className="p-button-warning mr-2 mb-2" onClick={updateAdminLeave} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddEditAdminLeave;

import * as types from "../ActionTypes/actionTypes";

const initialState = {
    loginData: [],
    getAllUsers: [],
    newusers: [],
    deleteUser: [],
    // updateuser: [],
    isLoading: false,
    isSuccess: false,
};

const usersReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.ADMIN_LOGIN_START:
        case types.LOAD_USERS_START:
        case types.ADD_USERS_START:
        case types.DELETE_USER_START:
        case types.UPDATE_USER_START:
            return {
                ...state,
                isSuccess: false,
                isLoading: true,
            };
        case types.ADMIN_LOGIN_SUCCESS:
            return {
                ...state,
                loginData: action.payload,
                isSuccess: true,
                isLoading: false,
            };
        case types.LOAD_USERS_SUCCESS:
            return {
                ...state,
                getAllUsers: action.payload,
                isLoading: false,
                isSuccess: false,
            };
        case types.ADD_USERS_SUCCESS:
            return {
                ...state,
                newusers: action.payload,
                isLoading: false,
                isSuccess: true,
            };
        case types.DELETE_USER_SUCCESS:
            return {
                ...state,
                deleteUser: action.payload,
                isLoading: false,
                isSuccess: true,
            };
        case types.UPDATE_USER_SUCCESS:
            return {
                ...state,
                updateuser: action.payload,
                isLoading: false,
                isSuccess: true,
            };
        case types.ADMIN_LOGIN_ERROR:
        case types.LOAD_USERS_ERROR:
        case types.ADD_USERS_ERROR:
        case types.DELETE_USER_ERROR:
        case types.UPDATE_USER_ERROR:
            return{
                ...state,
                isSuccess:false,
                isLoading:false
            }

        default:
            return state;
    }
};

export default usersReducer;

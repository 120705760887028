import * as types from "../ActionTypes/adminLeaveType";

export const getAdminLeaveStart = () => ({
    type: types.GET_ADMINLEAVE_START,
});

export const getAdminLeaveSuccess = (admin) => ({
    type: types.GET_ADMINLEAVE_SUCCESS,
    payload: admin,
});

export const getAdminLeaveError = (error) => ({
    type: types.GET_ADMINLEAVE_ERROR,
    payload: error,
});


export const updateAdminLeaveStart = (admin) => ({
    type: types.UPDATE_ADMINLEAVE_START,
    payload: admin,
});

export const updateAdminLeaveSuccess = (admin) => ({
    type: types.UPDATE_ADMINLEAVE_SUCCESS,
    payload: admin,
});

export const updateAdminLeaveError = (error) => ({
    type: types.UPDATE_ADMINLEAVE_ERROR,
    payload: error,
});
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import classNames from "classnames";
import { addUsersStart, loadUsersStart } from "../../redux/Actions/actions";
import { loadProjectsStart } from "../../redux/Actions/projectAction";
import { addPartsStart, updatePartsStart } from "../../redux/Actions/partsAction";
import { Dropdown } from "primereact/dropdown";

const emptyFields = {
    part_name: "",
    emp_id: "",
    projet_id: "",
};

const AddEditParts = () => {
    const [data, setData] = useState(emptyFields);
    const { part_name, emp_id, projet_id } = data;
    const [submitted, setSubmitted] = useState(false);
    const { id } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const [editMode, setEditMode] = useState(false);
    const users = useSelector((state) => state?.loginData?.getAllUsers);
    const getallprojects = useSelector((state) => state?.getprojects);
    const ProjectsData = getallprojects?.getAllProjects;
    const partsData = useSelector((state) => state?.parts?.partsList);
    const part_d = useSelector((state) => state?.parts);
    const isSuccess = part_d.isSuccess

    useEffect(() => {
        dispatch(loadUsersStart());
    }, []);

    useEffect(() => {
        dispatch(loadProjectsStart());
    }, []);

    useEffect(() => {
        if (isSuccess === true) {
            history.push("/admindashboard/parts-list");
        }
    }, [isSuccess]);

    useEffect(() => {
        if (id) {
            setEditMode(true);
            const partsValueList = partsData ? partsData.find((item) => item?.id === Number(id)) : null;
            const usersList = users ? users.find((item) => item?.id === partsValueList.emp_id) : null;
            const projectsListdata = ProjectsData ? ProjectsData.find((item) => item.id === partsValueList.projet_id) : null;
            setData({
                ...data,
                part_name: partsValueList.part_name,
                emp_id: usersList,
                projet_id: projectsListdata,
            });
        } else {
            setEditMode(false);
        }
    }, [id]);

    const gotoPrevious = () => {
        history.goBack();
    };

    const addParts = (e) => {
        e.preventDefault();
        setSubmitted(true);
        if (!editMode) {
            const add_parts = {
                part_name: data.part_name,
                emp_id: data.emp_id?.id,
                projet_id: data.projet_id?.id,
            };
            dispatch(addPartsStart(add_parts));
        }
        else {
            const update_parts = {
                id:parseInt(id),
                part_name: data.part_name,
                emp_id: data.emp_id?.id,
                projet_id: data.projet_id?.id,
            };
            dispatch(updatePartsStart(update_parts));
        }
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        setData({ ...data, [name]: val });
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <div className="font-medium text-4xl text-900 mb-3"> {!editMode ? "Add New Parts" : `Update Parts`}</div>
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Back" icon="pi pi-angle-left" className="p-button-secondary mr-2" onClick={gotoPrevious} />
                </div>
            </React.Fragment>
        );
    };

    return (
        <div className="surface-section card" style={{ margin: "1%", padding: "1%" }}>
            <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
            <div className="col-12 md:col-6">
                <div className="card p-fluid">
                    <div className="field">
                        <label htmlFor="Id">Parts Name</label>
                        <InputText id="id" name="part_name" placeholder="Enter Parts Name" value={data.part_name} onChange={(e) => onInputChange(e, "part_name")} className={classNames({ "p-invalid": submitted && !data.part_name })} autoFocus />
                        {submitted && !data.part_name && <small className="p-error">Parts Name is required.</small>}
                    </div>
                    <div className="field">
                        <label htmlFor="Id">Select Employee</label>
                        <Dropdown
                            key={'id'}
                            value={data.emp_id}
                            onChange={(e) => onInputChange(e, 'emp_id')}
                            options={users}
                            optionLabel="username"
                            placeholder="Select Employee"
                            className="w-full md:w-50rem"
                        />
                        {(submitted && !data.emp_id && <small className="p-error">Select Employee is required.</small>)}
                    </div>
                    <div className="field">
                        <label htmlFor="Id">Select Project</label>
                        <Dropdown
                            key={'id'}
                            value={data.projet_id}
                            onChange={(e) => onInputChange(e, 'projet_id')}
                            options={ProjectsData}
                            optionLabel="project_name"
                            placeholder="Select Project"
                            className="w-full md:w-50rem"
                        />
                        {(submitted && !data.projet_id && <small className="p-error">Select Project is required.</small>)}
                    </div>

                    <div className="formgrid grid">
                        <div className="field col">
                            <Button label="Cancel" icon="pi pi-times" className="p-button-secondary mr-2 mb-2" onClick={gotoPrevious} />
                        </div>
                        <div className="field col">
                            <Button label={!editMode ? "Add" : "UPDATE"} icon="pi pi-check" className="p-button-warning mr-2 mb-2" onClick={addParts} />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default AddEditParts;

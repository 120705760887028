import { all } from "redux-saga/effects";
import userSaga from "./usersagas";
import projectsSaga from "./projectSaga";
import inventorysSagas from "./inventorySaga";
import TransportersSagas from "./transporterSaga";
import filtersSaga from "./filtersSaga";
import tasksSaga from "./tasksSaga";
import partsSaga from "./partsSaga";
import adminLeaveSaga from "./adminLeaveSaga";
import reportsSaga from "./reportSaga";
export default function* rootSaga() {
    yield all([userSaga(), projectsSaga(), inventorysSagas(), TransportersSagas(), filtersSaga(), tasksSaga(), partsSaga(), adminLeaveSaga(), reportsSaga()]);
}

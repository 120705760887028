import * as types from "../ActionTypes/actionTypes";
import { takeLatest, put, all, fork, call } from "redux-saga/effects";
import Swal from "sweetalert2";

import { adminLoginSuccess, adminLoginError, loadUsersSuccess, laodUsersError, addUsersSuccess, addUsersError, deleteUserSuccess, deleteUserError, updateUserSuccess, updateUserError } from "../Actions/actions";

import { adminLoginApi, loadUsersApi, addUsersApi, deleteUserApi, updateUser } from "../APIs/api";

const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 4000,
});

export function* onAdminLoginStartAsync({ payload }) {
    try {
        const response = yield call(adminLoginApi, payload);
        if (response?.data?.msg === "Login Success") {
            sessionStorage.setItem("ADMIN", JSON.stringify(response?.data?.token));
            Toast.fire({
                icon: "success",
                title: response?.data?.msg,
            });
            yield put(adminLoginSuccess(response?.data));
        } else {
            Toast.fire({
                icon: "error",
                title: response?.data?.msg,
            });
            return response;
        }
    } catch (error) {
        yield put(adminLoginError(error.response));
        Toast.fire({
            icon: "error",
            title: 'something went wrong',
        });
    }
}

export function* onLoadUsersStartAsync() {
    try {
        const response = yield call(loadUsersApi);
        if (response.status === 201) {
            yield put(loadUsersSuccess(response?.data));
        }
    } catch (error) {
        yield put(laodUsersError(error));
    }
}

export function* onAddUsersStartAsync({ payload }) {
    try {
        const response = yield call(addUsersApi, payload);
        if (response?.data?.status === true) {
            yield put(addUsersSuccess(response));

            Toast.fire({
                icon: "success",
                title: response?.data?.msg,
            });
        } else {
            Toast.fire({
                icon: "error",
                title: response?.data?.msg,
            });
            return response;
        }
    } catch (error) {
        yield put(addUsersError(error.response));
        // Toast.fire({
        //     icon: "error",
        //     title: error.response?.data?.msg,
        // });
    }
}

export function* onDeleteUserStartAsync(userId) {
    try {
        const response = yield call(deleteUserApi, userId.payload);

        if (response?.status === 201) {
            yield put(deleteUserSuccess(userId));
            Toast.fire({
                icon: "success",
                title: response.data.msg,
            });
        } else {
            Toast.fire({
                icon: "error",
                title: response.data.msg,
            });
        }
    } catch (error) {
        yield put(deleteUserError(error.response));
    }
}
export function* onUpdateUserStartAsync(updateuser) {
    try {
        const response = yield call(updateUser, updateuser?.payload);
        if (response?.status === 201) {
            yield put(updateUserSuccess(response));
            Toast.fire({
                icon: "success",
                title: "User update successfully",
            });
        } else {
            Toast.fire({
                icon: "error",
                title: response.data.msg,
            });
        }
    } catch (error) {
        yield put(updateUserError(error.response));
    }
}

export function* onAdminLogin() {
    yield takeLatest(types.ADMIN_LOGIN_START, onAdminLoginStartAsync);
}

export function* onLoadUsers() {
    yield takeLatest(types.LOAD_USERS_START, onLoadUsersStartAsync);
}
export function* onUpdateUsers() {
    yield takeLatest(types.UPDATE_USER_START, onUpdateUserStartAsync);
}
export function* onDeleteUsers() {
    yield takeLatest(types.DELETE_USER_START, onDeleteUserStartAsync);
}
export function* onAddUsers() {
    yield takeLatest(types.ADD_USERS_START, onAddUsersStartAsync);
}
const userSagas = [fork(onAdminLogin), fork(onLoadUsers), fork(onAddUsers), fork(onDeleteUsers), fork(onUpdateUsers)];

export default function* userSaga() {
    yield all([...userSagas]);
}

import * as types from "../ActionTypes/actionTypes";

export const adminLoginStart = (user) => ({
    type: types.ADMIN_LOGIN_START,
    payload: user,
});

export const adminLoginSuccess = (login) => ({
    type: types.ADMIN_LOGIN_SUCCESS,
    payload: login,
});

export const adminLoginError = (error) => ({
    type: types.ADMIN_LOGIN_ERROR,
    payload: error,
});

export const loadUsersStart = () => ({
    type: types.LOAD_USERS_START,
});

export const loadUsersSuccess = (users) => ({
    type: types.LOAD_USERS_SUCCESS,
    payload: users,
});

export const laodUsersError = (error) => ({
    type: types.LOAD_USERS_ERROR,
    payload: error,
});

export const addUsersStart = (newusers) => ({
    type: types.ADD_USERS_START,
    payload: newusers,
});

export const addUsersSuccess = (newusers) => ({
    type: types.ADD_USERS_SUCCESS,
    payload: newusers,
});

export const addUsersError = (error) => ({
    type: types.ADD_USERS_ERROR,
    payload: error,
});

export const deleteUserStart = (userId) => ({
    type: types.DELETE_USER_START,
    payload: userId,
});

export const deleteUserSuccess = (userId) => ({
    type: types.DELETE_USER_SUCCESS,
    payload: userId,
});

export const deleteUserError = (error) => ({
    type: types.DELETE_USER_ERROR,
    payload: error,
});

export const updateUserStart = (updateuser) => {
    return {
      type: types.UPDATE_USER_START,
      payload: updateuser,
    };
  };
  
  export const updateUserSuccess = (updateuser) => {
    return {
      type: types.UPDATE_USER_SUCCESS,
      payload: updateuser,
    };
  };
  
  export const updateUserError = (error) => {
    return {
      type: types.UPDATE_USER_ERROR,
      payload: {},
      error: error,
    };
  };
  


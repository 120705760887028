import * as types from "../ActionTypes/partsTypes";
import { takeLatest, put, all, fork, call } from "redux-saga/effects";
import Swal from "sweetalert2";

import { getPartsSuccess, getPartsError, addPartsSuccess, addPartsError, updatePartsSuccess, updatePartsError, deletePartsSuccess, deletePartsError } from "../Actions/partsAction";

import { getPartsApi, addPartsApi, deletePartsApi, updatePartsApi } from "../APIs/partsApi";

const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 4000,
});

export function* onGetPartsStartAsync() {
    try {
        const response = yield call(getPartsApi);
        if (response?.status === 201) {
            yield put(getPartsSuccess(response?.data));
        }
    } catch (error) {
        yield put(getPartsError(error));
    }
}

export function* onAddPartsStartAsync({ payload }) {
    try {
        const response = yield call(addPartsApi, payload);
        if (response?.status === 201) {
            yield put(addPartsSuccess(response?.data));
            Toast.fire({
                icon: "success",
                title: response?.data?.msg,
            });
        }
        // else {
        //     Toast.fire({
        //         icon: "error",
        //         title: response?.data?.msg,
        //     });
        //     return response;
        // }
    } catch (error) {
        yield put(addPartsError(error.response));
        // Toast.fire({
        //     icon: "error",
        //     title: error.response?.data?.msg,
        // });
    }
}

export function* onDeletePartsStartAsync(payload) {
    try {
        const response = yield call(deletePartsApi, payload.payload);
        if (response?.status === 201) {
            yield put(deletePartsSuccess(response));
            Toast.fire({
                icon: "success",
                title: response.data.msg,
            });
        } else {
            Toast.fire({
                icon: "error",
                title: response.data.msg,
            });
        }
    } catch (error) {
        yield put(deletePartsError(error.response));
    }
}

export function* onUpdatePartsStartAsync(payload) {
    try {
        const response = yield call(updatePartsApi, payload);
        if (response?.status === 201) {
            yield put(updatePartsSuccess(response));
            Toast.fire({
                icon: "success",
                title: response.data.msg,
            });
        }
        // else {
        //     Toast.fire({
        //         icon: "error",
        //         title: response.data.msg,
        //     });
        // }
    } catch (error) {
        yield put(updatePartsError(error.response));
    }
}

export function* onGetParts() {
    yield takeLatest(types.GET_PARTS_START, onGetPartsStartAsync);
}

export function* onDeleteParts() {
    yield takeLatest(types.DELETE_PARTS_START, onDeletePartsStartAsync);
}

export function* onAddParts() {
    yield takeLatest(types.ADD_PARTS_START, onAddPartsStartAsync);
}
export function* onUpdateParts() {
    yield takeLatest(types.UPDATE_PARTS_START, onUpdatePartsStartAsync);
}

const partsSagas = [fork(onGetParts), fork(onAddParts), fork(onUpdateParts), fork(onDeleteParts)];

export default function* partsSaga() {
    yield all([...partsSagas]);
}

import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { projectViewStart } from "../../redux/Actions/projectAction";
import { Link } from "react-router-dom";

const ProjectView = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const dt = useRef(null);
    const [projectValue, setProjectValue] = useState();
    const [deleteUserDialog, setDeleteUserDialog] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const projectViewData = useSelector((state) => state?.projectView);
    const ProjectsViewList = projectViewData?.projectView;
    const isSuccess = projectViewData?.isSuccess;
    const isLoading = projectViewData?.isLoading;

    useEffect(() => {
        dispatch(projectViewStart());
    }, [isSuccess]);

    const gotoPrevious = () => {
        history.goBack();
    };

    const hideDeleteUserDialog = () => {
        setDeleteUserDialog(false);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Link to={`/addnew-project/`}>
                        <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" />
                    </Link>
                </div>
            </React.Fragment>
        );
    };
    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Back" icon="pi pi-angle-left" className="p-button-secondary mr-2" onClick={gotoPrevious} />
                </div>
            </React.Fragment>
        );
    };
    const idBodyTemplate = (rowData, index) => {
        return (
            <>
                <div className="actions">
                    <p>
                        <b>{index.rowIndex + 1}</b>
                    </p>
                </div>
            </>
        );
    };
    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">List Of Project</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const deleteUsersDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteUserDialog} />
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card" style={{ margin: "1%" }}>
                    <Toolbar className="mb-4" right={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={ProjectsViewList}
                        loading={isLoading}
                        dataKey="id"
                        paginator
                        rows={5}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Users"
                        globalFilter={globalFilter}
                        emptyMessage="No Users found."
                        header={header}
                        responsiveLayout="scroll"
                    >
                        {/* <Column field="id" header="ID" sortable headerStyle={{ width: "14%", minWidth: "10rem" }}></Column> */}
                        <Column body={idBodyTemplate} header={'Id'} ></Column>
                        <Column style={{ display: "none" }} field="uniqueId" header="UNIQUEID" sortable headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="emp_name" header="Employee Name " sortable headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="projet_name" header="Project Name" sortable headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="part_name" header="Part Nmae" sortable headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="task_name" header="Task Name" sortable headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="part_description" header="Part Description" sortable headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="working_status" header="Working Status" sortable headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="total_hours" header="Total Hours" sortable headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                    </DataTable>

                    <Dialog visible={deleteUserDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteUsersDialogFooter} onHide={hideDeleteUserDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                            {projectValue && (
                                <span>
                                    Are you sure you want to delete <b>{projectValue.id}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ProjectView, comparisonFn);

export const LOAD_TRANSPORTER_START = "LOAD_TRANSPORTER_START";
export const LOAD_TRANSPORTER_SUCCESS = "LOAD_TRANSPORTER_SUCCESS";
export const LOAD_TRANSPORTER_ERROR = "LOAD_TRANSPORTER_ERROR";

export const ADD_TRANSPORTER_START = "ADD_TRANSPORTER_START";
export const ADD_TRANSPORTER_SUCCESS = "ADD_TRANSPORTER_SUCCESS";
export const ADD_TRANSPORTER_ERROR = "ADD_TRANSPORTER_ERROR";

export const DELETE_TRANSPORTER_START = "DELETE_TRANSPORTER_START";
export const DELETE_TRANSPORTER_SUCCESS = "DELETE_TRANSPORTER_SUCCESS";
export const DELETE_TRANSPORTER_ERROR = "DELETE_TRANSPORTER_ERROR";

export const UPDATE_TRANSPORTER_START = "UPDATE_TRANSPORTER_START";
export const UPDATE_TRANSPORTER_SUCCESS = "UPDATE_TRANSPORTER_SUCCESS";
export const UPDATE_TRANSPORTER_ERROR = "UPDATE_TRANSPORTER_ERROR";

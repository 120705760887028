import * as types from "../ActionTypes/inventoryType";



export const loadinventoryStart = () => ({
    type: types.LOAD_INVENTORY_START,
});

export const loadinventorySuccess = (inventory) => ({
    type: types.LOAD_INVENTORY_SUCCESS,
    payload: inventory,
});

export const laodinventoryError = (error) => ({
    type: types.LOAD_INVENTORY_ERROR,
    payload: error,
});


export const deleteinventoryStart = (inventoryId) => ({
    type: types.DELETE_INVENTORY_START,
    payload: inventoryId,
});

export const deleteinventorySuccess = (inventoryId) => ({
    type: types.DELETE_INVENTORY_SUCCESS,
    payload: inventoryId,
});

export const deleteinventoryError = (error) => ({
    type: types.DELETE_INVENTORY_ERROR,
    payload: error,
});

export const addInventoryStart = (inventory) => ({
    type: types.ADD_INVENTORY_START,
    payload: inventory,
});

export const addInventorySuccess = (inventory) => ({
    type: types.ADD_INVENTORY_SUCCESS,
    payload: inventory,
});

export const addInventoryError = (error) => ({
    type: types.ADD_INVENTORY_ERROR,
    payload: error,
});


export const updateInventoryStart = (inventory) => {
    return {
      type: types.UPDATE_INVENTORY_START,
      payload: inventory,
    };
  };
  
  export const updateInventorySuccess = (inventory) => {
    return {
      type: types.UPDATE_INVENTORY_SUCCESS,
      payload: inventory,
    };
  };
  
  export const updateInventoryError = (error) => {
    return {
      type: types.UPDATE_INVENTORY_ERROR,
      payload: {},
      error: error,
    };
  };
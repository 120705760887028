import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import classNames from "classnames";
import { addUsersStart, loadUsersStart } from "../../redux/Actions/actions";
import { loadProjectsStart } from "../../redux/Actions/projectAction";
import { getPartsStart } from "../../redux/Actions/partsAction";
import { addTasksStart, updateTasksStart } from "../../redux/Actions/tasksAction";
import { Dropdown } from "primereact/dropdown";

const emptyFields = {
    opretions: "",
    emp_id: "",
    projet_id: "",
    parts_id: "",
    working_status: "",
    parts_quantity: "",
};

const AddEditTasks = () => {
    const [data, setData] = useState(emptyFields);
    const { opretions, emp_id, projet_id, parts_id, working_status, parts_quantity } = data;
    const [submitted, setSubmitted] = useState(false);
    const { id } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const [editMode, setEditMode] = useState(false);
    const users = useSelector((state) => state?.loginData?.getAllUsers);
    const getallprojects = useSelector((state) => state?.getprojects);
    const ProjectsData = getallprojects?.getAllProjects;
    const partsData = useSelector((state) => state?.parts?.partsList);
    const tasksList = useSelector((state) => state?.gettasks?.getAllTask?.data);
    const task_d = useSelector((state) => state?.addtasks);
    const isSuccess = task_d?.isSuccess;

    const status = [
        { name: "Pending", code: "Pending" },
        { name: "Complete ", code: "Complete" },
        { name: "InProgress", code: "InProgress" },
        { name: "Hold", code: "Hold" },
        // { name: "InProgress", code: "InProgress" },
    ];

    useEffect(() => {
        dispatch(loadProjectsStart());
        dispatch(getPartsStart());
        dispatch(loadUsersStart());
    }, []);

    // useEffect(() => {
    //     if (isSuccessUpdate === 201) {
    //         history.push("/admindashboard/users-list");
    //     }
    // }, [isSuccessUpdate]);

    useEffect(() => {
        if (isSuccess === true) {
            history.push("/admindashboard/tasks");
        }
    }, [isSuccess]);

    useEffect(() => {
        if (id) {
            setEditMode(true);
            const tasklist = tasksList ? tasksList.find((item) => item?.id === Number(id)) : null
            const usersList = users ? users.find((item) => item?.id === tasklist?.emp_id) : null;
            const partsListdata = partsData ? partsData.find((item) => item.id === tasklist?.parts_id) : null;
            const projectsListdata = ProjectsData ? ProjectsData?.find((item) => item?.id === tasklist?.projet_id) : null;
            setData({
                ...data,
                opretions: tasklist?.opretions,
                parts_quantity: tasklist?.parts_quantity,
                emp_id: usersList,
                projet_id: projectsListdata,
                parts_id: partsListdata,
                working_status: tasklist?.working_status,
            });
        } else {
            setEditMode(false);
        }
    }, [id]);

    const gotoPrevious = () => {
        history.goBack();
    };

    const addParts = (e) => {
        e.preventDefault();
        setSubmitted(true);
        if (!editMode) {
            const add_Tsaks = {
                opretions: data?.opretions,
                emp_id: data?.emp_id?.id,
                projet_id: data?.projet_id?.id,
                working_status: data?.working_status,
                parts_quantity: data?.parts_quantity,
                parts_id: data?.parts_id?.id,
            };
            dispatch(addTasksStart(add_Tsaks));
        } else {
            const update_Tasks = {
                id: id,
                opretions: data?.opretions,
                emp_id: data.emp_id?.id,
                projet_id: data?.projet_id?.id,
                working_status: data?.working_status,
                parts_quantity: data?.parts_quantity,
                parts_id: data?.parts_id?.id,
            };

            dispatch(updateTasksStart(update_Tasks));
        }
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        setData({ ...data, [name]: val });
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <div className="font-medium text-4xl text-900 mb-3"> {!editMode ? "Add New Tasks" : `Update Tasks`}</div>
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Back" icon="pi pi-angle-left" className="p-button-secondary mr-2" onClick={gotoPrevious} />
                </div>
            </React.Fragment>
        );
    };

    return (
        <div className="surface-section card" style={{ margin: "1%", padding: "1%" }}>
            <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
            <div className="col-12 md:col-6">
                <div className="card p-fluid">
                    <div className="field">
                        <label htmlFor="Id">Select Employee</label>
                        <Dropdown key={"id"} value={data?.emp_id} onChange={(e) => onInputChange(e, "emp_id")} options={users} optionLabel="username" placeholder="Select Employee" className="w-full md:w-50rem" />
                        {submitted && !data?.emp_id && <small className="p-error">Select Employee is required.</small>}
                    </div>
                    <div className="field">
                        <label htmlFor="Id">Select Project</label>
                        <Dropdown key={"id"} value={data?.projet_id} onChange={(e) => onInputChange(e, "projet_id")} options={ProjectsData} optionLabel="project_name" placeholder="Select Project" className="w-full md:w-50rem" />
                        {submitted && !data?.projet_id && <small className="p-error">Select Project is required.</small>}
                    </div>
                    <div className="field">
                        <label htmlFor="Id">Select Parts</label>
                        <Dropdown key={"id"} value={data.parts_id} onChange={(e) => onInputChange(e, "parts_id")} options={partsData} optionLabel="part_name" placeholder="Select Parts" className="w-full md:w-50rem" />
                        {submitted && !data.parts_id && <small className="p-error">Select parts is required.</small>}
                    </div>
                    <div className="field">
                        <label htmlFor="Id">Parts Quantity</label>
                        <InputText id="id" type="number" name="parts_quantity" placeholder="Enter Parts Quantity" value={data.parts_quantity} onChange={(e) => onInputChange(e, "parts_quantity")} className={classNames({ "p-invalid": submitted && !data.parts_quantity })}  />
                        {submitted && !data.parts_quantity && <small className="p-error"> Parts Quantity is required.</small>}
                    </div>
                    <div className="field">
                        <label htmlFor="Id">Opretions Name</label>
                        <InputText id="id" name="opretions" placeholder="Enter Opretions Name" value={data?.opretions} onChange={(e) => onInputChange(e, "opretions")} className={classNames({ "p-invalid": submitted && !data.opretions })} autoFocus />
                        {submitted && !data?.opretions && <small className="p-error">Opretions Name is required.</small>}
                    </div>
                    <div className="field">
                        <label htmlFor="Id">Project Status</label>
                        <Dropdown name="working_status" id="name" optionValue="name" value={data?.working_status} onChange={(e) => onInputChange(e, "working_status")} options={status} optionLabel="name" showClear placeholder="Select a Status" className="w-full md:w-50rem" />
                        {submitted && !data?.working_status && <small className="p-error">Select project status.</small>}
                    </div>
                   
                    <div className="formgrid grid">
                        <div className="field col">
                            <Button label="Cancel" icon="pi pi-times" className="p-button-secondary mr-2 mb-2" onClick={gotoPrevious} />
                        </div>
                        <div className="field col">
                            <Button label={!editMode ? "Add" : "UPDATE"} icon="pi pi-check" className="p-button-warning mr-2 mb-2" onClick={addParts} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddEditTasks;

import React from 'react';

export const AppFooter = (props) => {
    return (
        <div className="layout-footer">
            {/* <img src={'assets/layout/images/koli-logo.png'} alt="Logo" height="20" className="mr-2" /> */}
            <span className="font-medium ml-2"><b>Copyright © 2023 Shree Vishnu Dies & Tools | All rights Reserved 
                {/* | Contact */}
                </b></span>
        </div>
    );
}

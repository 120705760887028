

export const LOAD_PROJECTS_START = "LOAD_PROJECTS_START";
export const LOAD_PROJECTS_SUCCESS = "LOAD_PROJECTS_SUCCESS";
export const LOAD_PROJECTS_ERROR = "LOAD_PROJECTS_ERROR";

export const ADD_PROJECTS_START = "ADD_PROJECTS_START";
export const ADD_PROJECTS_SUCCESS = "ADD_PROJECTS_SUCCESS";
export const ADD_PROJECTS_ERROR = "ADD_PROJECTS_ERROR";

export const DELETE_PROJECTS_START = 'DELETE_PROJECTS_START'
export const DELETE_PROJECTS_SUCCESS = 'DELETE_PROJECTS_SUCCESS'
export const DELETE_PROJECTS_ERROR = 'DELETE_PROJECTS_ERROR'

export const UPDATE_PROJECTS_START = 'UPDATE_PROJECTS_START'
export const UPDATE_PROJECTS_SUCCESS = 'UPDATE_PROJECTS_SUCCESS'
export const UPDATE_PROJECTS_ERROR = 'UPDATE_PROJECTS_ERROR'

export const PROJECTS_VIEW_START = 'PROJECTS_VIEW_START'
export const PROJECTS_VIEW_SUCCESS = 'PROJECTS_VIEW_SUCCESS'
export const PROJECTS_VIEW_ERROR = 'PROJECTS_VIEW_ERROR'
import * as types from "../ActionTypes/tasksType";



export const loadTasksStart = () => ({
    type: types.LOAD_TASKS_START,
});

export const loadTasksSuccess = (tasks) => ({
    type: types.LOAD_TASKS_SUCCESS,
    payload: tasks,
});

export const laodTasksError = (error) => ({
    type: types.LOAD_TASKS_ERROR,
    payload: error,
});

export const addTasksStart = (newtasks) => ({
    type: types.ADD_TASKS_START,
    payload: newtasks,
});

export const addTasksSuccess = (newtasks) => ({
    type: types.ADD_TASKS_SUCCESS,
    payload: newtasks,
});

export const addTasksError = (error) => ({
    type: types.ADD_TASKS_ERROR,
    payload: error,
});

export const deleteTasksStart = (taskId) => ({
    type: types.DELETE_TASKS_START,
    payload: taskId,
});

export const deleteTasksSuccess = (taskId) => ({
    type: types.DELETE_TASKS_SUCCESS,
    payload: taskId,
});

export const deleteTasksError = (error) => ({
    type: types.DELETE_TASKS_ERROR,
    payload: error,
});

export const updateTasksStart = (updatetask) => {
    return {
      type: types.UPDATE_TASKS_START,
      payload: updatetask,
    };
  };
  
  export const updateTasksSuccess = (updatetask) => {
    return {
      type: types.UPDATE_TASKS_SUCCESS,
      payload: updatetask,
    };
  };
  
  export const updateTasksError = (error) => {
    return {
      type: types.UPDATE_TASKS_ERROR,
      payload: {},
      error: error,
    };
  };
  


import Axios from "axios";
import { baseUrl } from "./baseUrl";

const token = JSON.parse(sessionStorage.getItem("ADMIN"));

const headersParam = {
    Authorization: `Token ${token}`,
    Origin: "*",
};

// const baseUrl = "https://shreevishnuapi.koliinfotech.com/";

export const getAdminLeaveApi = async () => await Axios.get(`${baseUrl}LeaveapplicationViewAdmin/`, { headers: headersParam });

export const updateAdminLeaveApi = async (updateAdminLeave) => await Axios.put(`${baseUrl}LeaveapplicationUpdate/${updateAdminLeave.payload.id}/`, updateAdminLeave.payload, { headers: headersParam });

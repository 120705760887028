import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { filtersUserStart } from "../../redux/Actions/filtersAction";
import { Link } from "react-router-dom";

const FilterUsers = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const dt = useRef(null);
    const [userValue, setUserValue] = useState();
    const [deleteUserDialog, setDeleteUserDialog] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const users = useSelector((state) => state?.loginData);
    const UsersData = users?.getAllUsers;
    const isSuccess = users?.isSuccess;
    const isLoading = users?.isLoading;

    useEffect(() => {
        dispatch(filtersUserStart());
    }, []);

    const gotoPrevious = () => {
        history.goBack();
    };

    const hideDeleteUserDialog = () => {
        // setDeleteUserDialog(false);
    };

    const confirmDeleteUsers = (userValue) => {
        setUserValue(userValue);
        // setDeleteUserDialog(true);
    };

    // const deleteUsers = async () => {
    //     setUserValue(userValue);
    //     dispatch(deleteUserStart(userValue));
    //     setDeleteUserDialog(false);
    // };

    const actionBodyTemplate = (rowData) => {

        return (
            <div className="actions">
             
                <Link to={`/update-users/${rowData.id}`}>
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-success  mt-2 mr-2" />
                </Link>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2 mr-2" onClick={() => confirmDeleteUsers(rowData)} />
            </div>
        );
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Link to={`/add-new-user`}>
                        <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" />
                    </Link>
                </div>
            </React.Fragment>
        );
    };
    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Back" icon="pi pi-angle-left" className="p-button-secondary mr-2" onClick={gotoPrevious} />
                    
                </div>
            </React.Fragment>
        );
    };
    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">List Of Users</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const deleteUsersDialogFooter = (
        <>
            {/* <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteUserDialog} /> */}
            {/* <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteUsers} /> */}
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card" style={{ margin: "1%" }}>
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                  
                    <DataTable
                        ref={dt}
                        value={UsersData}
                        loading={isLoading}
                        dataKey="id"
                        paginator
                        rows={5}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Users"
                        globalFilter={globalFilter}
                        emptyMessage="No Users found."
                        header={header}
                        responsiveLayout="scroll"
                    >
                        <Column field="id" header="ID" sortable headerStyle={{ width: "14%", minWidth: "10rem" }} ></Column>
                        <Column style={{ display: "none" }} field="uniqueId" header="UNIQUEID" sortable headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="username" header="User Name " sortable headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="email" header="Email " sortable headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={deleteUserDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteUsersDialogFooter} onHide={hideDeleteUserDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                            {userValue && (
                                <span>
                                    Are you sure you want to delete <b>{userValue.id}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(FilterUsers, comparisonFn);

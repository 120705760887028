import axios from "axios";
import { baseUrl } from "./baseUrl";
const token = JSON.parse(sessionStorage.getItem("ADMIN"));
const headersParam = {
    Authorization: `Token ${token}`,
    Origin: "*",
};

// const baseUrl = "https://shreevishnuapi.koliinfotech.com/";
 
export const addTasksApi = async (newtasks) => await axios.post(`${baseUrl}task/`, newtasks, { headers: headersParam });

export const deleteTasksApi = async (taskid) => await axios.delete(`${baseUrl}deletetasks/${taskid.id}`, { headers: headersParam });

export const loadTasksApi = async () => await axios.get(`${baseUrl}alltasks/`, { headers: headersParam });
export const updateTasks = async (updatetasks) => await axios.put(`${baseUrl}updatetasks/${updatetasks?.id}/`, updatetasks, { headers: headersParam });

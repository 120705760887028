import axios from "axios";
import { baseUrl } from "./baseUrl";
const token = JSON.parse(sessionStorage.getItem("ADMIN"));
const headersParam = {
    Authorization: `Token ${token}`,
    Origin: "*",
};

// const baseUrl = "https://shreevishnuapi.koliinfotech.com/";
 
// export const adminLoginApi = async (user) => await axios.post(`${baseUrl}/login/`, user);

export const addTransporterApi = async (transporter) => await axios.post(`${baseUrl}transporter/`, transporter, { headers: headersParam });

export const deleteTransporterApi = async (transporterId) => await axios.delete(`${baseUrl}deletetransporter/${transporterId.id}`, { headers: headersParam });

export const loadTransporterApi = async () => await axios.get(`${baseUrl}alltransporter/`, { headers: headersParam });
export const updateTransporterApi = async (transporter) => await axios.put(`${baseUrl}updatetransporter/${transporter?.id}/`, transporter, { headers: headersParam });

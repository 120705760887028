import * as types from "../ActionTypes/reportTypes";
import { takeLatest, put, all, fork, call } from "redux-saga/effects";
import Swal from "sweetalert2";

import { getPartsSuccess, getPartsError, addPartsSuccess, addPartsError, updatePartsSuccess, updatePartsError, deletePartsSuccess, deletePartsError } from "../Actions/partsAction";

import { getReportSuccess,getReportError } from "../Actions/reportAction";

import { getPartsApi, addPartsApi, deletePartsApi, updatePartsApi } from "../APIs/partsApi";
import {getReports} from "../APIs/reportApi"

const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 4000,
});

export function* onGetReportStartAsync(payload) {
    try {
        console.log('onGetReportStartAsync~~~~~~~~~~~>',payload)
        const response = yield call(getReports,payload);
        console.log('onGetReportStartAsync respomce~~~~~~~~~~~>',response)
        
        if (response?.status === 200) {
            yield put(getReportSuccess(response?.data));
        }
    } catch (error) {
        yield put(getReportError(error));
    }
}


export function* onGetReports() {
    yield takeLatest(types.GET_REPORT_START, onGetReportStartAsync);
}


const reportsSagas = [
    fork(onGetReports)
];

export default function* reportsSaga() {
    yield all([...reportsSagas]);
}

import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import classNames from "classnames";
import { addTransporterStart, loadTransporterStart, updateTransporterStart } from "../../redux/Actions/transporterAction";
const emptyFields = {
    name: "",
    vehicle_type: "",
    contact_number: "",
};

const AddEditTransporter = () => {
    const [data, setData] = useState(emptyFields);
    const { name, contact_number, vehicle_type } = data;
    const [submitted, setSubmitted] = useState(false);
    const { id } = useParams();
    const [editMode, setEditMode] = useState(false);
    const transporter = useSelector((state) => state?.gettransporter?.getAllTransporter?.data);
    const isSuccess = useSelector((state) => state?.addtransporter?.newTransporter?.status);
    const isSuccessUpdate = useSelector((state) => state?.gettransporter?.updateTransporter?.status);

    const validatePhone = (phone) => {
        const phoneRe = /^(([0-9]{10}))$/;
        return phoneRe.test(String(phone));
    };
    useEffect(() => {
        dispatch(loadTransporterStart());
    }, []);
    useEffect(() => {
        if (isSuccessUpdate === 201) {
            history.push("/admindashboard/tronsporter-list");
        }
    }, [isSuccessUpdate]);
    useEffect(() => {
        if (isSuccess === 201) {
            history.push("/admindashboard/tronsporter-list");
        }
    }, [isSuccess]);

    useEffect(() => {
        if (id) {
            setEditMode(true);
            const dataUpdate = transporter ? transporter?.find((item) => item.id === Number(id)) : null;
            setData({ ...dataUpdate });
        } else {
            setEditMode(false);
            setData({ ...data });
        }
    }, [id]);

    const gotoPrevious = () => {
        history.goBack();
    };

    const history = useHistory();
    const dispatch = useDispatch();

    const addInventory = (e) => {
        e.preventDefault();
        setSubmitted(true);
        if (!editMode) {
            if (validatePhone(data.contact_number) && data.name && data.vehicle_type) {
                const add_Transporter = {
                    name: name,
                    contact_number: contact_number,
                    vehicle_type: vehicle_type,
                };
                dispatch(addTransporterStart(add_Transporter));
            }
        } else {
            const update_Transporter = {
                id: id,
                name: name,
                contact_number: contact_number,
                vehicle_type: vehicle_type,
            };
            dispatch(updateTransporterStart(update_Transporter, id));
        }
    };

    const onInputChange = (e) => {
        const value = e.target.value;
        setData({
            ...data,
            [e.target.name]: value,
        });
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <div className="font-medium text-4xl text-900 mb-3"> {!editMode ? "Add New Transporter" : `Update Transporter`}</div>
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Back" icon="pi pi-angle-left" className="p-button-secondary mr-2" onClick={gotoPrevious} />
                </div>
            </React.Fragment>
        );
    };

    return (
        <div className="surface-section card" style={{ margin: "1%", padding: "1%" }}>
            <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
            <div className="col-12 md:col-6">
                <div className="card p-fluid">
                    <div className="field">
                        <label htmlFor="Id">Transporter Name</label>
                        <InputText id="id" name="name" value={data?.name} onChange={(e) => onInputChange(e, "name")} className={classNames({ "p-invalid": submitted && !data.name })} autoFocus />
                        {submitted && !data.name && <small className="p-error">Name is required.</small>}
                    </div>

                    <div className="field">
                        <label htmlFor="Id">Contact Number</label>
                        <InputText id="contact_number" type="number" name="contact_number" maxLength={10} value={data?.contact_number} onChange={(e) => onInputChange(e, "contact_number")} className={classNames({ "p-invalid": submitted && !data.contact_number && !validatePhone(data.contact_number) })} required />
                        {/* {submitted && !data.contact_number && <small className="p-error">contact_number is required.</small>} */}
                        {(submitted && !data?.contact_number && <small className="p-error">Required Contact Number</small>) || (submitted && !validatePhone(data?.contact_number) && <small className="p-error">Enter Valid Contact Number</small>)}
                    </div>

                    <div className="field">
                        <label htmlFor="Id"> Vehicle Type</label>
                        <InputText id="id" name="vehicle_type" value={data?.vehicle_type} onChange={(e) => onInputChange(e, "vehicle_type")} className={classNames({ "p-invalid": submitted && !data.vehicle_type })} toggleMask />
                        {submitted && !data.vehicle_type && <small className="p-error">vehicle_type is required.</small>}
                    </div>

                    <div className="formgrid grid">
                        <div className="field col">
                            <Button label="Cancel" icon="pi pi-times" className="p-button-secondary mr-2 mb-2" onClick={gotoPrevious} />
                        </div>
                        <div className="field col">
                            <Button label={!editMode ? "Add" : "UPDATE"} icon="pi pi-check" className="p-button-warning mr-2 mb-2" onClick={addInventory} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddEditTransporter;

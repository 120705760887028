import * as types from "../ActionTypes/tasksType";
import { takeLatest, put, all, fork, call } from "redux-saga/effects";
import Swal from "sweetalert2";

import { loadTasksSuccess, laodTasksError, addTasksSuccess, addTasksError, deleteTasksSuccess, deleteTasksError, updateTasksSuccess, updateTasksError } from "../Actions/tasksAction";

import { loadTasksApi, addTasksApi, deleteTasksApi, updateTasks } from "../APIs/tasksApi";

const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 4000,
});

export function* onLoadTasksStartAsync() {
    try {
        const response = yield call(loadTasksApi);
        // if (response.status === 201) {
        yield put(loadTasksSuccess(response));
        //  }
    } catch (error) {
        yield put(laodTasksError(error));
    }
}

export function* onAddTasksStartAsync({ payload }) {
    try {
        const response = yield call(addTasksApi, payload);
        if (response?.data?.status === true) {
            yield put(addTasksSuccess(response));
            Toast.fire({
                icon: "success",
                title: response?.data?.msg,
            });
        } else {
            Toast.fire({
                icon: "error",
                title: response?.data?.msg,
            });
            return response;
        }
    } catch (error) {
        yield put(addTasksError(error.response));
        // Toast.fire({
        //     icon: "error",
        //     title: error.response?.data?.msg,
        // });
    }
}

export function* onDeleteTasksStartAsync(payload) {
    try {
        const response = yield call(deleteTasksApi, payload.payload);

        if (response?.status === 201) {
            yield put(deleteTasksSuccess(payload));
            Toast.fire({
                icon: "success",
                title: response.data.msg,
            });
        } else {
            Toast.fire({
                icon: "error",
                title: response.data.msg,
            });
        }
    } catch (error) {
        yield put(deleteTasksError(error.response));
    }
}
export function* onUpdateTasksStartAsync(payload) {
    try {
        const response = yield call(updateTasks, payload?.payload);
        if (response?.status === 201) {
            yield put(updateTasksSuccess(response));
            Toast.fire({
                icon: "success",
                title: "User update successfully",
            });
        } else {
            Toast.fire({
                icon: "error",
                title: response.data.msg,
            });
        }
    } catch (error) {
        yield put(updateTasksError(error.response));
    }
}

export function* onLoadTasks() {
    yield takeLatest(types.LOAD_TASKS_START, onLoadTasksStartAsync);
}
export function* onUpdateTasks() {
    yield takeLatest(types.UPDATE_TASKS_START, onUpdateTasksStartAsync);
}
export function* onDeleteTask() {
    yield takeLatest(types.DELETE_TASKS_START, onDeleteTasksStartAsync);
}
export function* onAddUsers() {
    yield takeLatest(types.ADD_TASKS_START, onAddTasksStartAsync);
}
const tasksSagas = [fork(onLoadTasks), fork(onAddUsers), fork(onDeleteTask), fork(onUpdateTasks)];

export default function* tasksSaga() {
    yield all([...tasksSagas]);
}

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { loadUsersStart } from "../redux/Actions/actions";
import { loadProjectsStart } from "../redux/Actions/projectAction";
import { loadinventoryStart } from "../redux/Actions/inventoryAciton";
import { loadTransporterStart } from "../redux/Actions/transporterAction";
import TypeWriterEffect from "react-typewriter-effect";

const Dashboard = (props) => {
    const dispatch = useDispatch();
    const users = useSelector((state) => state?.loginData?.getAllUsers);
    const getallprojects = useSelector((state) => state?.getprojects?.getAllProjects);
    const inventory = useSelector((state) => state?.getinventory?.getAllInventory?.data);
    const transporter = useSelector((state) => state?.gettransporter?.getAllTransporter?.data);

    useEffect(() => {
        setTimeout(() => {
            dispatch(loadUsersStart());
            dispatch(loadProjectsStart());
            dispatch(loadinventoryStart());
            dispatch(loadTransporterStart());
        }, 1000);
    }, []);
    return (
        <div style={{ overflowY: "hidden", overflowX: "hidden" }}>
            <div className="grid">
                <div className="col-12 lg:col-6 xl:col-3">
                    <Link to="/admindashboard/users-list">
                        <div className="card mb-0">
                            <div className="flex justify-content-between mb-3">
                                <div>
                                    <span className="block text-500 font-medium mb-3">USERS</span>
                                    <div className="text-900 font-medium text-xl">{users?.length}</div>
                                </div>
                                <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                    <i className="pi pi-users text-blue-500 text-xl" />
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-12 lg:col-6 xl:col-3">
                    <Link to="/admindashboard/projects-list">
                        <div className="card mb-0">
                            <div className="flex justify-content-between mb-3">
                                <div>
                                    <span className="block text-500 font-medium mb-3">Projects</span>
                                    <div className="text-900 font-medium text-xl">{getallprojects?.length}</div>
                                </div>
                                <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                    <i className="pi pi-book text-blue-500 text-xl" />
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-12 lg:col-6 xl:col-3">
                    <Link to="/admindashboard/projects-list">
                        <div className="card mb-0">
                            <div className="flex justify-content-between mb-3">
                                <div>
                                    <span className="block text-500 font-medium mb-3">Inventory</span>
                                    <div className="text-900 font-medium text-xl">{inventory?.length}</div>
                                </div>
                                <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                    <i className="pi pi-box text-blue-500 text-xl" />
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-12 lg:col-6 xl:col-3">
                    <Link to="/admindashboard/projects-list">
                        <div className="card mb-0">
                            <div className="flex justify-content-between mb-3">
                                <div>
                                    <span className="block text-500 font-medium mb-3">Transporter</span>
                                    <div className="text-900 font-medium text-xl">{transporter?.length}</div>
                                </div>
                                <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                    <i className="pi pi-car text-blue-500 text-xl" />
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>

            <div className="card align-items-center justify-content-center  mt-3">
                <div className="card align-items-center justify-content-center  mt-3 text-center">
                    <img src={"assets/layout/images/banner.png"} alt="Image" height={"100%"} width={"50%"} className="align-items-center rounded mx-auto d-block" />
                </div>
                <TypeWriterEffect
                    startDelay={10}
                    cursorColor="white"
                    text="Shree Vishnu Dies and Tools is a leading company specializing in the creation of high-quality dies and tools. With a team of skilled engineers and technicians, they design and manufacture precision solutions for various industries. Their commitment to excellence, customer satisfaction, and sustainability sets them apart in the competitive market. From automotive to electronics, Shree Vishnu Dies and Tools delivers tailored products that optimize production processes and meet the most demanding requirements."
                    typeSpeed={10}
                    textStyle={{ fontSize: "2rem" }}
                />
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Dashboard, comparisonFn);

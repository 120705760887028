import * as types from "../ActionTypes/projectType";

export const loadProjectsStart = () => ({
    type: types.LOAD_PROJECTS_START,
});

export const loadProjectsSuccess = (Projects) => ({
    type: types.LOAD_PROJECTS_SUCCESS,
    payload: Projects,
});

export const laodProjectsError = (error) => ({
    type: types.LOAD_PROJECTS_ERROR,
    payload: error,
});

export const deleteProjectStart = (projectId) => ({
    type: types.DELETE_PROJECTS_START,
    payload: projectId,
});

export const deleteProjectSuccess = (projectId) => ({
    type: types.DELETE_PROJECTS_SUCCESS,
    payload: projectId,
});

export const deleteProjectError = (error) => ({
    type: types.DELETE_PROJECTS_ERROR,
    payload: error,
});

export const addProjectsStart = (newproject) => ({
    type: types.ADD_PROJECTS_START,
    payload: newproject,
});

export const addProjectsSuccess = (newproject) => ({
    type: types.ADD_PROJECTS_SUCCESS,
    payload: newproject,
});

export const addProjectsError = (error) => ({
    type: types.ADD_PROJECTS_ERROR,
    payload: error,
});

export const updateProjectsStart = (updateproject) => {
    return {
        type: types.UPDATE_PROJECTS_START,
        payload: updateproject,
    };
};

export const updateProjectsSuccess = (updateproject) => {
    return {
        type: types.UPDATE_PROJECTS_SUCCESS,
        payload: updateproject,
    };
};

export const updateProjectsError = (error) => {
    return {
        type: types.UPDATE_PROJECTS_ERROR,
        payload: {},
        error: error,
    };
};

export const projectViewStart = (Projects) => ({
    type: types.PROJECTS_VIEW_START,
    payload: Projects,
});

export const projectViewSuccess = (Projects) => ({
    type: types.PROJECTS_VIEW_SUCCESS,
    payload: Projects,
});

export const projectViewError = (error) => ({
    type: types.PROJECTS_VIEW_ERROR,
    payload: error,
});


export const LOAD_INVENTORY_START = "LOAD_INVENTORY_START";
export const LOAD_INVENTORY_SUCCESS = "LOAD_INVENTORY_SUCCESS";
export const LOAD_INVENTORY_ERROR = "LOAD_INVENTORY_ERROR";

export const ADD_INVENTORY_START = "ADD_INVENTORY_START";
export const ADD_INVENTORY_SUCCESS = "ADD_INVENTORY_SUCCESS";
export const ADD_INVENTORY_ERROR = "ADD_INVENTORY_ERROR";

export const DELETE_INVENTORY_START = 'DELETE_INVENTORY_START'
export const DELETE_INVENTORY_SUCCESS = 'DELETE_INVENTORY_SUCCESS'
export const DELETE_INVENTORY_ERROR = 'DELETE_INVENTORY_ERROR'

export const UPDATE_INVENTORY_START = 'UPDATE_INVENTORY_START'
export const UPDATE_INVENTORY_SUCCESS = 'UPDATE_INVENTORY_SUCCESS'
export const UPDATE_INVENTORY_ERROR = 'UPDATE_INVENTORY_ERROR'
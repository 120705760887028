import * as types from "../ActionTypes/adminLeaveType";
import { takeLatest, put, all, fork, call } from "redux-saga/effects";
import Swal from "sweetalert2";

import { getAdminLeaveSuccess, getAdminLeaveError, updateAdminLeaveSuccess, updateAdminLeaveError } from "../Actions/adminLeaveAction";

import { getAdminLeaveApi, updateAdminLeaveApi } from "../APIs/adminLeave";

const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 4000,
});

export function* onGetAdminLeaveStartAsync() {
    try {
        const response = yield call(getAdminLeaveApi);
        if (response?.status === 200) {
            yield put(getAdminLeaveSuccess(response));
        }
    } catch (error) {
        yield put(getAdminLeaveError(error));
    }
}

export function* onUpdateAdminLeaveStartAsync(payload) {
    try {
        const response = yield call(updateAdminLeaveApi, payload);
        if (response?.status === 201) {
            yield put(updateAdminLeaveSuccess(response));
            Toast.fire({
                icon: "success",
                title: response?.statusText,
            });
        } 
        // else {
        //     Toast.fire({
        //         icon: "error",
        //         title: response.data.msg,
        //     });
        // }
    } catch (error) {
        yield put(updateAdminLeaveError(error.response));
    }
}

export function* onGetAdminLeave() {
    yield takeLatest(types.GET_ADMINLEAVE_START, onGetAdminLeaveStartAsync);
}

export function* onUpdateAdminLeave() {
    yield takeLatest(types.UPDATE_ADMINLEAVE_START, onUpdateAdminLeaveStartAsync);
}

const adminLeavSagas = [fork(onGetAdminLeave), fork(onUpdateAdminLeave)];

export default function* adminLeaveSaga() {
    yield all([...adminLeavSagas]);
}

import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import classNames from "classnames";
import { addInventoryStart, loadinventoryStart, updateInventoryStart } from "../../redux/Actions/inventoryAciton";
const emptyFields = {
    inventory_name: "",
    supplier_name: "",
    supplier_address: "",
    supplier_contact: "",
    stock_quantity: "",
    costing: "",
};

const AddEditInventory = () => {
    const [data, setData] = useState(emptyFields);
    const { inventory_name, supplier_contact, supplier_name, supplier_address, costing, stock_quantity } = data;
    const [submitted, setSubmitted] = useState(false);
    const { id } = useParams();
    const [editMode, setEditMode] = useState(false);
    const inventory = useSelector((state) => state?.getinventory?.getAllInventory?.data);
    const isSuccess = useSelector((state) => state?.addInventory?.newInventory?.status);
    const isSuccessUpdate = useSelector((state) => state?.updateinventory?.updateInventory?.status);

    const validatePhone = (phone) => {
        const phoneRe = /^(([0-9]{10}))$/;
        return phoneRe.test(String(phone));
    };
    useEffect(() => {
        dispatch(loadinventoryStart());
    }, []);
    useEffect(() => {
        if (isSuccessUpdate === 201) {
            history.push("/admindashboard/inventory-list");
        }
    }, [isSuccessUpdate]);
    useEffect(() => {
        if (isSuccess === 201) {
            history.push("/admindashboard/inventory-list");
        }
    }, [isSuccess]);

    useEffect(() => {
        if (id) {
            setEditMode(true);
            const dataUpdate = inventory ? inventory?.find((item) => item.id === Number(id)) : null;
            setData({ ...dataUpdate });
        } else {
            setEditMode(false);
            setData({ ...data });
        }
    }, [id]);

    const gotoPrevious = () => {
        history.goBack();
    };

    const history = useHistory();
    const dispatch = useDispatch();

    const addInventory = (e) => {
        setSubmitted(true);
        e.preventDefault();
        if(data.inventory_name !="" && data.supplier_contact !="" && data.supplier_name !="" && data.supplier_address !="" && data.costing !="" && data.stock_quantity !=""){
            if (!editMode) {
                if (validatePhone(data.supplier_contact)) {
                    const add_Inventory = {
                        inventory_name: inventory_name,
                        supplier_contact: supplier_contact,
                        supplier_name: supplier_name,
                        supplier_address: supplier_address,
                        costing: costing,
                        stock_quantity: stock_quantity,
                    };
                    dispatch(addInventoryStart(add_Inventory));
                }
            } else {
                const update_Inventory = {
                    id: id,
                    inventory_name: inventory_name,
                    supplier_contact: supplier_contact,
                    supplier_name: supplier_name,
                    supplier_address: supplier_address,
                    costing: costing,
                    stock_quantity: stock_quantity,
                };
                dispatch(updateInventoryStart(update_Inventory, id));
            }
        }
    
    };

    const onInputChange = (e) => {
        const value = e.target.value;
        setData({
            ...data,
            [e.target.name]: value,
        });
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <div className="font-medium text-4xl text-900 mb-3"> {!editMode ? "Add New Inventory" : `Update Inventory`}</div>
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Back" icon="pi pi-angle-left" className="p-button-secondary mr-2" onClick={gotoPrevious} />
                </div>
            </React.Fragment>
        );
    };

    return (
        <div className="surface-section card" style={{ margin: "1%", padding: "1%" }}>
            <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
            <div className="col-12 md:col-6">
                <div className="card p-fluid">
                    <div className="field">
                        <label htmlFor="Id">Inventory Name</label>
                        <InputText id="id" name="inventory_name" value={data?.inventory_name} onChange={(e) => onInputChange(e, "inventory_name")} className={classNames({ "p-invalid": submitted && !data.inventory_name })} autoFocus />
                        {submitted && !data.inventory_name && <small className="p-error">Inventory name is required.</small>}
                    </div>

                    <div className="field">
                        <label htmlFor="Id">Supplier Contact</label>
                        <InputText id="supplier_contact" type="number" name="supplier_contact" maxLength={10} value={data?.supplier_contact} onChange={(e) => onInputChange(e, "supplier_contact")} className={classNames({ "p-invalid": submitted && !data.supplier_contact && !validatePhone(data.supplier_contact) })} required />
                        {(submitted && !data?.supplier_contact && <small className="p-error">Required Contact Number</small>) || (submitted && !validatePhone(data?.supplier_contact) && <small className="p-error">Enter Valid Contact Number</small>)}
                    </div>
                    <div className="field">
                        <label htmlFor="Id">Costing</label>
                        <InputText id="id" name="costing" type="number" value={data?.costing} onChange={(e) => onInputChange(e, "costing")} className={classNames({ "p-invalid": submitted && !data.costing })}  />
                        {submitted && !data.costing && <small className="p-error">costing is required.</small>}
                    </div>
                    <div className="field">
                        <label htmlFor="Id">Stock Quantity</label>
                        <InputText id="id" name="stock_quantity" type="number" value={data?.stock_quantity} onChange={(e) => onInputChange(e, "stock_quantity")} className={classNames({ "p-invalid": submitted && !data.stock_quantity })}  />
                        {submitted && !data.stock_quantity && <small className="p-error">stock_quantity is required.</small>}
                    </div>
                    <div className="formgrid grid">
                        <div className="field col">
                            <label htmlFor="Id"> Supplier Name</label>
                            <InputText id="id" name="supplier_name" value={data?.supplier_name} onChange={(e) => onInputChange(e, "supplier_name")} className={classNames({ "p-invalid": submitted && !data.supplier_name })} toggleMask />
                            {submitted && !data.supplier_name && <small className="p-error">supplier_name is required.</small>}
                        </div>

                        <div className="field col">
                            <label htmlFor="Id">Supplier Address</label>
                            <InputText id="id" name="supplier_address" value={data?.supplier_address} onChange={(e) => onInputChange(e, "supplier_address")} className={classNames({ "p-invalid": submitted && !data.supplier_address })}  />
                            {submitted && !data.supplier_address && <small className="p-error">supplier_address is required.</small>}
                        </div>
                    </div>
                    <div className="formgrid grid">
                        <div className="field col">
                            <Button label="Cancel" icon="pi pi-times" className="p-button-secondary mr-2 mb-2" onClick={gotoPrevious} />
                        </div>
                        <div className="field col">
                            <Button label={!editMode ? "Add" : "UPDATE"} icon="pi pi-check" className="p-button-warning mr-2 mb-2" onClick={addInventory} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddEditInventory;

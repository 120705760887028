
import * as types from "../ActionTypes/filtersType";

export const filtersUserStart = (user) => ({
    type: types.FILTERS_USERS_START,
    payload: user,
});

export const filtersUserSuccess = (login) => ({
    type: types.FILTERS_USERS_SUCCESS,
    payload: login,
});

export const filtersUserError = (error) => ({
    type: types.FILTERS_USERS_ERROR,
    payload: error,
});

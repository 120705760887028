export const GET_PARTS_START = "GET_PARTS_START";
export const GET_PARTS_SUCCESS = "GET_PARTS_SUCCESS";
export const GET_PARTS_ERROR = "GET_PARTS_ERROR";

export const ADD_PARTS_START = "ADD_PARTS_START";
export const ADD_PARTS_SUCCESS = "ADD_PARTS_SUCCESS";
export const ADD_PARTS_ERROR = "ADD_PARTS_ERROR";

export const DELETE_PARTS_START = "DELETE_PARTS_START";
export const DELETE_PARTS_SUCCESS = "DELETE_PARTS_SUCCESS";
export const DELETE_PARTS_ERROR = "DELETE_PARTS_ERROR";

export const UPDATE_PARTS_START = "UPDATE_PARTS_START";
export const UPDATE_PARTS_SUCCESS = "UPDATE_PARTS_SUCCESS";
export const UPDATE_PARTS_ERROR = "UPDATE_PARTS_ERROR";
import * as types from "../ActionTypes/ransporterType";



export const loadTransporterStart = () => ({
    type: types.LOAD_TRANSPORTER_START,
});

export const loadTransporterSuccess = (transporter) => ({
    type: types.LOAD_TRANSPORTER_SUCCESS,
    payload: transporter,
});

export const laodTransporterError = (error) => ({
    type: types.LOAD_TRANSPORTER_ERROR,
    payload: error,
});

export const addTransporterStart = (newtransporter) => ({
    type: types.ADD_TRANSPORTER_START,
    payload: newtransporter,
});

export const addTransporterSuccess = (newtransporter) => ({
    type: types.ADD_TRANSPORTER_SUCCESS,
    payload: newtransporter,
});

export const addTransporterError = (error) => ({
    type: types.ADD_TRANSPORTER_ERROR,
    payload: error,
});

export const deleteTransporterStart = (transporterId) => ({
    type: types.DELETE_TRANSPORTER_START,
    payload: transporterId,
});

export const deleteTransporterSuccess = (transporterId) => ({
    type: types.DELETE_TRANSPORTER_SUCCESS,
    payload: transporterId,
});

export const deleteTransporterError = (error) => ({
    type: types.DELETE_TRANSPORTER_ERROR,
    payload: error,
});

export const updateTransporterStart = (transporter) => {
    return {
      type: types.UPDATE_TRANSPORTER_START,
      payload: transporter,
    };
  };
  
  export const updateTransporterSuccess = (transporter) => {
    return {
      type: types.UPDATE_TRANSPORTER_SUCCESS,
      payload: transporter,
    };
  };
  
  export const updateTransporterError = (error) => {
    return {
      type: types.UPDATE_TRANSPORTER_ERROR,
      payload: {},
      error: error,
    };
  };
  


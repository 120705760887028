import Axios from "axios";
import { baseUrl } from "./baseUrl";

const token = JSON.parse(sessionStorage.getItem("ADMIN"));

const headersParam = {
    Authorization: `Token ${token}`,
    Origin: "*",
};

// const baseUrl ="https://shreevishnuapi.koliinfotech.com/";

export const getPartsApi = async () => await Axios.get(`${baseUrl}allparts/`, { headers: headersParam });

export const addPartsApi = async (newParts) => await Axios.post(`${baseUrl}parts/`, newParts, { headers: headersParam });

export const deletePartsApi = async (deleteParts) => await Axios.delete(`${baseUrl}deleteparts/${deleteParts}/`, { headers: headersParam });

export const updatePartsApi = async (updateParts) => await Axios.put(`${baseUrl}updateparts/${updateParts?.payload?.id}/`, updateParts.payload, { headers: headersParam });

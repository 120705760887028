import * as types from "../ActionTypes/projectType";
import { takeLatest, put, all, fork, call } from "redux-saga/effects";
import Swal from "sweetalert2";

import { loadProjectsSuccess, laodProjectsError, deleteProjectSuccess, updateProjectsSuccess, updateProjectsError, deleteProjectError, addProjectsSuccess, addProjectsError, projectViewSuccess, projectViewError } from "../Actions/projectAction";

import { loadProjectsApi, deleteProjectsApi, addProjectsApi, updateProjectApi, projectsViewApi } from "../APIs/projectApi";

const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 4000,
});

export function* onLoadProjectsStartAsync() {
    try {
        const response = yield call(loadProjectsApi);
        if (response.status === 201) {
            yield put(loadProjectsSuccess(response?.data));
        }
    } catch (error) {
        yield put(laodProjectsError(error));
    }
}

export function* onDeleteProjectsStartAsync(userId) {
    try {
        const response = yield call(deleteProjectsApi, userId.payload);
        if (response?.status === 201) {
            yield put(deleteProjectSuccess(userId));
            Toast.fire({
                icon: "success",
                title: response.data.msg,
            });
        } else {
            Toast.fire({
                icon: "error",
                title: response.data.msg,
            });
        }
    } catch (error) {
        yield put(deleteProjectError(error.response));
    }
}

export function* onAddProjectsStartAsync({ payload }) {
    try {
        const response = yield call(addProjectsApi, payload);
        if (response?.data?.status === true) {
            yield put(addProjectsSuccess(response));
            Toast.fire({
                icon: "success",
                title: response?.data?.msg,
            });
        } 
        else {
            Toast.fire({
                icon: "error",
                title: response?.data?.msg,
            });
            return response;
        }
    } catch (error) {
        yield put(addProjectsError(error.response));
        // Toast.fire({
        //     icon: "error",
        //     title: error.response?.data?.msg,
        // });
    }
}

export function* onUpdateProjectsStartAsync(project) {
    try {
        const response = yield call(updateProjectApi, project?.payload);
        if (response?.status === 201) {
            yield put(updateProjectsSuccess(response));

            Toast.fire({
                icon: "success",
                title: "Project update successfully",
            });
        } else {
            Toast.fire({
                icon: "error",
                title: response.data.msg,
            });
        }
    } catch (error) {
        yield put(updateProjectsError(error.response));
    }
}

export function* onProjectViewStartAsync() {
    try {
        const response = yield call(projectsViewApi);
        if (response.status === 200) {
            yield put(projectViewSuccess(response?.data));
        }
    } catch (error) {
        yield put(projectViewError(error));
    }
}

export function* onLoadProjects() {
    yield takeLatest(types.LOAD_PROJECTS_START, onLoadProjectsStartAsync);
}
export function* onProjectViews() {
    yield takeLatest(types.PROJECTS_VIEW_START, onProjectViewStartAsync);
}

export function* onDeleteProjects() {
    yield takeLatest(types.DELETE_PROJECTS_START, onDeleteProjectsStartAsync);
}

export function* onAddProjects() {
    yield takeLatest(types.ADD_PROJECTS_START, onAddProjectsStartAsync);
}

export function* onUpdateProjects() {
    yield takeLatest(types.UPDATE_PROJECTS_START, onUpdateProjectsStartAsync);
}
const projectsSagas = [fork(onLoadProjects), fork(onDeleteProjects), fork(onAddProjects), fork(onUpdateProjects), fork(onProjectViews)];

export default function* projectsSaga() {
    yield all([...projectsSagas]);
}

import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { Route, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "../assets/demo/flags/flags.css";
import "../assets/demo/Demos.scss";
import "../assets/layout/layout.scss";
import "../App.scss";
import { Tooltip } from "primereact/tooltip";
import { AppTopbar } from "./AppTopbar";
import { AppFooter } from "./AppFooter";
import { AppMenu } from "./AppMenu";
import Dashboard from "./Dashboard";
import Users from "./menu/User";
import FilterUsers from "./menu/filterusers";
import Projects from "./menu/Projects";
import Inventory from "./menu/Inventory";
import Transporter from "./menu/Transporter";
import AddEditInventory from "../pages/EditComponent/AddEditInventory";
import AddEditUser from "../pages/EditComponent/AddEditUser";
import AddEditTransporter from "../pages/EditComponent/AddEditTransporter";
import AddEditProject from "../pages/EditComponent/AddEditProjects";
import AddEditTasks from "../pages/EditComponent/AddEditTasks";
import SinglrUsers from "../pages/SingleViews/SingleUser";
import Tasks from "./menu/Tasks";
import Parts from "./menu/Parts";
import AddEditParts from "../pages/EditComponent/AddEditParts";
import adminLeaveApplication from "./menu/adminLeaveApplication";
import AddEditAdminLeave from "../pages/EditComponent/AddEditAdminLeave";
import ProjectView from "./menu/ProjectView";
import Reports from './menu/Reports'

const AdminDashboard = () => {
    const [layoutMode, setLayoutMode] = useState("static");
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const copyTooltipRef = useRef();
    const location = useLocation();
    let menuClick = false;
    let mobileTopbarMenuClick = false;

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    };

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === "overlay") {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }
                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            } else if (layoutMode === "static") {
                setStaticMenuInactive((prevState) => !prevState);
            }
        } else {
            setMobileMenuActive((prevState) => !prevState);
        }
        event.preventDefault();
    };

    const onSidebarClick = () => {
        menuClick = true;
    };

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;
        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    };

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;
        event.preventDefault();
    };

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    };
    const isDesktop = () => {
        return window.innerWidth >= 992;
    };

    const menu = [
        {
            label: "Dashboard",
            items: [
                {
                    label: "DASHBOARD",
                    icon: "pi pi-fw pi-home",
                    to: "/admindashboard",
                },
            ],
        },
        {
            label: "Menu Hierarchy",
            icon: "pi pi-fw pi-search",
            items: [
                { label: "USERS INFO", icon: "pi pi-fw pi-users", to: "/admindashboard/users-list" },
                // { label: "PROJECTS", icon: "pi pi-fw pi-users", to: "/admindashboard/projects-list" },
                {
                    label: "PROJECTS",
                    icon: "pi pi-fw pi-book",
                    items: [
                        {
                            label: "PROJECTS", icon: "pi pi-fw pi-list", to: "/admindashboard/projects-list",
                        },
                        {
                            label: "PROJECTS VIEW", icon: "pi pi-fw pi-search", to: "/admindashboard/projectview-list"
                        },
                    ]
                },
                { label: "INVENTORYS", icon: "pi pi-fw pi-box", to: "/admindashboard/inventory-list" },
                { label: "TRANSPORTER", icon: "pi pi-fw pi-car", to: "/admindashboard/tronsporter-list" },
                // { label: "FILTERUSERS", icon: "pi pi-fw pi-users", to: "/admindashboard/filterusers" },
                { label: "TASKS", icon: "pi pi-fw pi-book", to: "/admindashboard/tasks" },
                { label: "PARTS", icon: "pi pi-fw pi-database", to: "/admindashboard/parts-list" },
                { label: "ADMIN SIDE LEAVE", icon: "pi pi-fw pi-user", to: "/admindashboard/adminLeave-list" },
                { label: "REPORTS", icon: "pi pi-fw pi-file-pdf", to: "/admindashboard/reports" },
            ],
        },
    ];

    const addClass = (element, className) => {
        if (element.classList) element.classList.add(className);
        else element.className += " " + className;
    };

    const removeClass = (element, className) => {
        if (element.classList) element.classList.remove(className);
        else element.className = element.className.replace(new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
    };

    const wrapperClass = classNames("layout-wrapper", {
        "layout-overlay": layoutMode === "overlay",
        "layout-static": layoutMode === "static",
        "layout-static-sidebar-inactive": staticMenuInactive && layoutMode === "static",
        "layout-overlay-sidebar-active": overlayMenuActive && layoutMode === "overlay",
        "layout-mobile-sidebar-active": mobileMenuActive,
    });

    return (
        <div className={wrapperClass} onClick={onWrapperClick}>
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />
            <AppTopbar onToggleMenuClick={onToggleMenuClick} mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />
            <div className="layout-sidebar pointer-events-auto" onClick={onSidebarClick}>
                <AppMenu model={menu} onMenuItemClick={onMenuItemClick} />
            </div>
            <div className="layout-main-container">
                <div className="layout-main">
                    <Route path="/admindashboard" exact render={() => <Dashboard location={location} />} />
                    <Route path="/admindashboard/users-list" component={Users} />
                    <Route path="/admindashboard/filterusers" component={FilterUsers} />
                    <Route path="/admindashboard/tronsporter-list" component={Transporter} />
                    <Route path="/singleusers/:id" component={SinglrUsers} />
                    <Route path="/add-new-user" component={AddEditUser} />
                    <Route path="/add-new-transporter" component={AddEditTransporter} />
                    <Route path="/add-new-inventory" component={AddEditInventory} />
                    <Route path="/update-users/:id" component={AddEditUser} />
                    <Route path="/update-transporter/:id" component={AddEditTransporter} />
                    <Route path="/admindashboard/projects-list" component={Projects} />
                    <Route path="/addnew-project/" component={AddEditProject} />
                    <Route path="/addnew-tasks/" component={AddEditTasks} />
                    <Route path="/admindashboard/inventory-list" component={Inventory} />
                    <Route path="/inventory-update/:id" component={AddEditInventory} />
                    <Route path="/project-update/:id" component={AddEditProject} />
                    <Route path="/admindashboard/tasks" component={Tasks} />
                    <Route path="/admindashboard/parts-list" component={Parts} />
                    <Route path="/add-new-parts/" component={AddEditParts} />
                    <Route path="/update-new-parts/:id" component={AddEditParts} />
                    <Route path="/update-new-tasks/:id" component={AddEditTasks} />
                    <Route path="/admindashboard/adminLeave-list" component={adminLeaveApplication} />
                    <Route path="/update-new-adminLeave/:id" component={AddEditAdminLeave} />
                    <Route path="/admindashboard/projectview-list" component={ProjectView} />
                    <Route path="/admindashboard/reports" component={Reports} />

                </div>
                <AppFooter />
            </div>
            <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                <div className="layout-mask p-component-overlay"></div>
            </CSSTransition>
        </div>
    );
};

export default AdminDashboard;

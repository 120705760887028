import * as types from "../ActionTypes/adminLeaveType";

const initialState = {
    adminLeave: [],
    isLoading: false,
    isSuccess: false,
};

const adminLeaveReducers = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ADMINLEAVE_START:
            return {
                isSuccess: false,
                isLoading: true,
            };
        case types.UPDATE_ADMINLEAVE_START:
            return {
                isSuccess: false,
                isLoading: true,
            };
        case types.GET_ADMINLEAVE_SUCCESS:
            return {
                ...state,
                adminLeave: action.payload,
                isLoading: false,
                isSuccess: false,
            };
        case types.UPDATE_ADMINLEAVE_SUCCESS:
            return {
                isLoading: false,
                isSuccess: true,
            };
        case types.GET_ADMINLEAVE_ERROR:
            return {
                isLoading: false,
                isSuccess: false,
            };
        case types.UPDATE_ADMINLEAVE_ERROR:
            return {
                isLoading: false,
                isSuccess: false,
            };
        default:
            return state;
    }
};

export default adminLeaveReducers;

import * as types from "../ActionTypes/filtersType";
import { takeLatest, put, all, fork, call } from "redux-saga/effects";
import Swal from "sweetalert2";

import { filtersUserSuccess, filtersUserError } from "../Actions/filtersAction";

import { filtersusers } from "../APIs/filtersApi";

const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 4000,
});

export function* onLoadFiltersStartAsync() {
    try {
        const response = yield call(filtersusers);
        // if (response.status === 201) {
            yield put(filtersUserSuccess(response?.data));
        // }
    } catch (error) {
        yield put(filtersUserError(error));
    }
}

export function* onLoadfilters() {
    yield takeLatest(types.FILTERS_USERS_START, onLoadFiltersStartAsync);
}

const foltersSaga = [fork(onLoadfilters)];

export default function* filtersSaga() {
    yield all([...foltersSaga]);
}

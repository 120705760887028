import * as types from "../ActionTypes/inventoryType";

const initialState = {
    getAllInventory: [],
    newInventory: [],
    deleteInventory: [],
    updateInventory: [],
    isLoading: false,
    isSuccess: false,
};

const inventoryReducers = (state = initialState, action) => {
    switch (action.type) {
        case types.LOAD_INVENTORY_START:
            return {
                isSuccess: false,
                isLoading: true,
            };
        case types.ADD_INVENTORY_START:
            return {
                isSuccess: false,
                isLoading: true,
            };
        case types.DELETE_INVENTORY_START:
            return {
                isSuccess: false,
                isLoading: true,
            };
        case types.UPDATE_INVENTORY_START:
            return {
                isSuccess: false,
                isLoading: true,
            };

        case types.LOAD_INVENTORY_SUCCESS:
            return {
                ...state,
                getAllInventory: action.payload,
                isLoading: false,
                isSuccess: false,
            };
        case types.ADD_INVENTORY_SUCCESS:
            return {
                ...state,
                newInventory: action.payload,
                isLoading: false,
                isSuccess: true,
            };
        case types.DELETE_INVENTORY_SUCCESS:
            return {
                ...state,
                deleteInventory: action.payload,
                isLoading: false,
                isSuccess: true,
            };
        case types.UPDATE_INVENTORY_SUCCESS:
            return {
                ...state,
                updateInventory: action.payload,
                isLoading: false,
                isSuccess: true,
            };
        case types.LOAD_INVENTORY_ERROR:
        case types.ADD_INVENTORY_ERROR:
        case types.DELETE_INVENTORY_ERROR:
        case types.UPDATE_INVENTORY_ERROR:

        default:
            return state;
    }
};

export default inventoryReducers;

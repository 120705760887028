import axios from "axios";
import { baseUrl } from "./baseUrl";
const token = JSON.parse(sessionStorage.getItem("ADMIN"));
const headersParam = {
    Authorization: `Token ${token}`,
    Origin: "*",
};

// const baseUrl = "https://shreevishnuapi.koliinfotech.com/"
export const adminLoginApi = async (user) => await axios.post(`${baseUrl}login/`, user);

export const addUsersApi = async (newusers) => await axios.post(`${baseUrl}register/`, newusers, { headers: headersParam });

export const deleteUserApi = async (userId) => await axios.delete(`${baseUrl}updateuser/${userId.id}`, { headers: headersParam });

export const loadUsersApi = async () => await axios.get(`${baseUrl}getallusers/`, { headers: headersParam });
export const updateUser = async (updateUser) => await axios.put(`${baseUrl}updateuser/${updateUser?.id}/`, updateUser, { headers: headersParam });

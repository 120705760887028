import * as types from "../ActionTypes/ransporterType";
import { takeLatest, put, all, fork, call } from "redux-saga/effects";
import Swal from "sweetalert2";

import { loadTransporterSuccess, laodTransporterError, deleteTransporterSuccess, deleteTransporterError, addTransporterSuccess, addTransporterError, updateTransporterSuccess, updateTransporterError } from "../Actions/transporterAction";

import { loadTransporterApi, deleteTransporterApi, addTransporterApi, updateTransporterApi } from "../APIs/transporterApi";

const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 4000,
});

export function* onLoadTransporterStartAsync() {
    try {
        const response = yield call(loadTransporterApi);
        if (response.status === 200) {
            yield put(loadTransporterSuccess(response));
        }
    } catch (error) {
        yield put(laodTransporterError(error));
    }
}

export function* onAddTransporterStartAsync({ payload }) {
    try {
        const response = yield call(addTransporterApi, payload);
        if (response?.status === 201) {
            yield put(addTransporterSuccess(response));
            Toast.fire({
                icon: "success",
                title: response?.data?.msg,
            });
        } else {
            Toast.fire({
                icon: "error",
                title: response?.data?.msg,
            });
            return response;
        }
    } catch (error) {
        yield put(addTransporterError(error.response));
        Toast.fire({
            icon: "error",
            title: error.response?.data?.msg,
        });
    }
}

export function* onDeleteTransporterStartAsync(TransporterId) {
    try {
        const response = yield call(deleteTransporterApi, TransporterId.payload);
        if (response?.status === 201) {
            yield put(deleteTransporterSuccess(TransporterId));
            Toast.fire({
                icon: "success",
                title: response.data.msg,
            });
        } else {
            Toast.fire({
                icon: "error",
                title: response.data.msg,
            });
        }
    } catch (error) {
        yield put(deleteTransporterError(error.response));
    }
}

export function* onupdateTransporterAsync(updateTransporter) {
    try {
        const response = yield call(updateTransporterApi, updateTransporter?.payload);
        if (response?.status === 201) {
            yield put(updateTransporterSuccess(response));
            Toast.fire({
                icon: "success",
                title: "User update successfully",
            });
        } else {
            Toast.fire({
                icon: "error",
                title: response.data.msg,
            });
        }
    } catch (error) {
        yield put(updateTransporterError(error.response));
    }
}
export function* onLoadTransporter() {
    yield takeLatest(types.LOAD_TRANSPORTER_START, onLoadTransporterStartAsync);
}

export function* onDeleteTransporter() {
    yield takeLatest(types.DELETE_TRANSPORTER_START, onDeleteTransporterStartAsync);
}

export function* onAddTransporter() {
    yield takeLatest(types.ADD_TRANSPORTER_START, onAddTransporterStartAsync);
}

export function* onUpdateInvrntory() {
    yield takeLatest(types.UPDATE_TRANSPORTER_START, onupdateTransporterAsync);
}
const TransporterSagas = [fork(onLoadTransporter), fork(onDeleteTransporter), fork(onAddTransporter), fork(onUpdateInvrntory)];

export default function* TransportersSagas() {
    yield all([...TransporterSagas]);
}

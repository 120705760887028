import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { loadTasksStart, deleteTasksStart } from "../../redux/Actions/tasksAction";
import { Link } from "react-router-dom";

const Tasks = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const dt = useRef(null);
    const [tasksValue, setTaskValue] = useState();
    const [deleteTaskDialog, setDeleteTaskDialog] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const tasksList = useSelector((state) => state?.gettasks);
    const tasksdata = tasksList?.getAllTask?.data;
    const isSuccess = tasksList?.isSuccess;
    const isLoading = tasksList?.isLoading;

    useEffect(() => {
        dispatch(loadTasksStart());
    }, [isSuccess]);

    const gotoPrevious = () => {
        history.goBack();
    };

    const hideDeleteTaskDialog = () => {
        setDeleteTaskDialog(false);
    };

    const confirmDeleteTasks = (tasksValue) => {
        setTaskValue(tasksValue);
        setDeleteTaskDialog(true);
    };

    const deleteTasks = async () => {
        setTaskValue(tasksValue);
        dispatch(deleteTasksStart(tasksValue));
        setDeleteTaskDialog(false);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Link to={`/update-new-tasks/${rowData.id}`}>
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-success  mt-2 mr-2" />
                </Link>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2 mr-2" onClick={() => confirmDeleteTasks(rowData)} />
            </div>
        );
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Link to={`/addnew-tasks/`}>
                        <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" />
                    </Link>
                </div>
            </React.Fragment>
        );
    };
    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Back" icon="pi pi-angle-left" className="p-button-secondary mr-2" onClick={gotoPrevious} />
                </div>
            </React.Fragment>
        );
    };
    const idBodyTemplate = (rowData, index) => {
        return (
            <>
                <div className="actions">
                    <p>
                        <b>{index.rowIndex + 1}</b>
                    </p>
                </div>
            </>
        );
    };
    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">List Of Tasks</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const deleteTaskDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteTaskDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteTasks} />
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card" style={{ margin: "1%" }}>
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                    <DataTable
                        ref={dt}
                        value={tasksdata}
                        loading={isLoading}
                        dataKey="id"
                        paginator
                        rows={5}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Tasks"
                        globalFilter={globalFilter}
                        emptyMessage="No Tasks found."
                        header={header}
                        responsiveLayout="scroll"
                    >
                        {/* <Column field="id" header="ID" sortable headerStyle={{ width: "14%", minWidth: "10rem" }}></Column> */}
                        <Column body={idBodyTemplate} header={'Id'}></Column>
                        <Column style={{ display: "none" }} field="uniqueId" header="UNIQUEID" sortable headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column
                            field="emp_name"
                            header="Employee Name"
                            sortable
                            headerStyle={{ width: "14%", minWidth: "10rem" }}
                        ></Column>
                        <Column field="opretions" header="Opretions " sortable headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="part_name" header="Parts Name " sortable headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="parts_quantity" header="parts_quantity " sortable headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="project_name" header="Project Name" sortable headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="working_status" header="Working_status " sortable headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column body={actionBodyTemplate} header={'Action'}></Column>
                    </DataTable>

                    <Dialog visible={deleteTaskDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteTaskDialogFooter} onHide={hideDeleteTaskDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                            {tasksValue && (
                                <span>
                                    Are you sure you want to delete <b>{tasksValue.id}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Tasks, comparisonFn);
